import { Box, getColor, useTheme } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";

import { useGetLocationTexts } from "@/app/hooks/use-get-locations-text/use-get-location-texts";

const AttributeTooltipView = ({ id = "" }) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { getLocationTextsWithCountry } = useGetLocationTexts();

  return (
    <ReactTooltip
      id={id || "attributes"}
      backgroundColor={getColor(theme, "shadow")}
      getContent={(attributes) => {
        let attribute = attributes?.split(",");
        if (id === "Locations") {
          attribute = getLocationTextsWithCountry(attribute);
        }
        return (
          <Box sx={{ maxWidth: 300 }}>
            <Box
              sx={{
                color: "text",
                fontWeight: "bold",
                fontFamily: "MintGroteskV08",
              }}
            >
              {t(id || "Attributes")}
            </Box>
            {attribute?.map((attributesString) => (
              <Box key={attributesString} sx={{ fontSize: 0, color: "text" }}>
                {t(attributesString)}
              </Box>
            ))}
          </Box>
        );
      }}
    />
  );
};

export const recAttributes = ["Eligibilities", "Locations", "Projects", "Fuel Sources", "Vintages"];
export const AttributesTooltip = () => (
  <>
    {recAttributes.map((attr) => (
      <AttributeTooltipView id={attr} key={attr} />
    ))}
  </>
);
