import { useOktaAuth } from "@okta/okta-react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { RECOrderAttributeOptions } from "@/app/lib/format-rec-options";

import { useRecOptions } from "../use-rec-options";

export type LocationTexts = {
  label: string;
  value: string;
  country: string;
};

/**
 * Provide various function to provide country of given location
 */
export const useGetLocationTexts = (args?: { options?: RECOrderAttributeOptions }) => {
  const auth = useOktaAuth();

  const { recOptions: queryOptions } = useRecOptions({
    skipQuery: !auth?.authState?.isAuthenticated || !!args?.options,
  });
  const { t } = useTranslation();

  const recOptions = args?.options ?? queryOptions;

  const getLocationTextsWithCountry = useCallback(
    <T extends boolean = false>(
      locations: string[],
      returnAsObject?: T,
    ): T extends true ? LocationTexts[] : string[] => {
      return locations?.map((loc) => {
        let fullLocation = loc;
        const country =
          recOptions?.locationOptions.find((opt) =>
            opt.options?.find((subOpt) => {
              const match =
                subOpt.label.toLowerCase() === loc.toLowerCase().trim() ||
                subOpt.value.toLowerCase() === loc.toLowerCase().trim();
              if (match) {
                /** AL -> Alabama */
                fullLocation = subOpt.label;
                return match;
              }
            }),
          )?.label ?? "";

        return returnAsObject
          ? {
              label: t(fullLocation),
              value: loc,
              country,
            }
          : t("{{locationText}}", {
              locationText: country ? fullLocation + " (" + country + ")" : loc,
            });
      }) as T extends true ? LocationTexts[] : string[];
    },
    [recOptions?.locationOptions, t],
  );

  return { getLocationTextsWithCountry };
};
