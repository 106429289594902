import { Box, Button, Flex, IconLoading, Modal } from "@powerledger/ui-component-lib";
import currency from "currency.js";
import { startCase } from "lodash";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { PaymentType } from "../../types/generated/graphql";
import { formatAmountWithCurrency } from "../csv-components";
import { ConfirmPaymentModalProps } from "./confirm-payment-modal.types";

const confirmText: Record<string, string> = {
  [PaymentType.Rebate]: "Are you sure you want to disburse a rebate of {{amount}} to {{target}}?",
};

export const ConfirmPaymentModal: FC<ConfirmPaymentModalProps> = ({
  onConfirm,
  visible,
  loading,
  closeModal,
  paymentData,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      maskClosable
      visible={!!visible}
      hideCloseButton={!!paymentData?.amount}
      onCancel={closeModal}
      sx={{ maxHeight: "inherit" }}
      title={t("Disburse {{paymentType}}", { paymentType: startCase(paymentData?.type.toLowerCase()) })}
      subtitle={
        paymentData?.amount &&
        t(confirmText[paymentData?.type], {
          amount: formatAmountWithCurrency(
            currency(paymentData?.amount?.amount || 0).intValue,
            paymentData?.amount.currencyCode,
          ),
          target: paymentData?.targetAccount,
        })
      }
    >
      {paymentData && (
        <Box>
          <Flex sx={{ justifyContent: "flex-end", mt: 4, gap: 2 }}>
            <Button type="button" variant="error" sx={{ width: 9 }} disabled={loading} onClick={() => closeModal()}>
              {t("Cancel")}
            </Button>
            <Button
              variant="primary"
              sx={{ width: 9, borderWidth: 0 }}
              disabled={loading}
              onClick={async () => {
                await onConfirm();
                closeModal();
              }}
            >
              {t("Confirm")}
            </Button>
            {loading && <IconLoading size="small" />}
          </Flex>
        </Box>
      )}
    </Modal>
  );
};
