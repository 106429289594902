import { NormalizedCacheObject } from "@apollo/client";
import { ApolloClient as ApolloClientType } from "@apollo/client/core/ApolloClient";

import Api, { ApiType } from "./api";
import ApolloClient from "./apollo-client";
import notifications, { NotificationsType } from "./notifications";

export type ContainerType = {
  api: ApiType;
  apolloClient: ApolloClientType<NormalizedCacheObject>;
  notifications: NotificationsType;
};

export const container: ContainerType = {
  api: Api(),
  apolloClient: ApolloClient(),
  notifications,
};

export default container;
