import { IconWarning } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";
import { Flex } from "theme-ui";

export const RequestInfoMessage = () => {
  const { t } = useTranslation();

  return (
    <Flex
      sx={{
        fontSize: 1,
        bg: "warning.500",
        borderRadius: 8,
        p: 2,
        alignItems: "center",
        mb: 3,
      }}
    >
      <IconWarning size={4} color="warning.800" />
      {t("Sort the requests in ascending order based on Requested At (oldest first), Then proceed with the actions.")}
    </Flex>
  );
};
