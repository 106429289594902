import { Box, Button, Editor, Flex, IconLoading, Modal, Paragraph } from "@powerledger/ui-component-lib";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";

import { ApprovalStatus } from "@/app/types/generated/graphql";

import { AccountRequestEditEntityModalViewProps } from "./account-request-edit-entity-modal.types";

export const AccountRequestEditEntityModalView = ({
  visible,
  closeModal,
  queryLoading,
  submitting,
  onConfirm,
  editReasons,
  setEditReasons,
  currentErrorMessage,
  title,
}: AccountRequestEditEntityModalViewProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      visible={visible}
      onCancel={closeModal}
      title={title}
      hideCloseButton={submitting}
      subtitle={t(
        "Reject will enable the fields for this entity in sign up stepper with the error message. Move to pending will clear the error message, move the entity back to pending state and the fields will not be enabled in sign up stepper. User will be able to edit required fields only after the whole application is rejected.",
      )}
    >
      {queryLoading && <IconLoading />}
      {!queryLoading && (
        <Box
          sx={{
            overflowY: "auto",
            maxHeight: 600,
          }}
        >
          <Box
            sx={{
              mb: 3,
              bg: "shadow",
              borderRadius: 3,
              p: 2,
              fontSize: 1,
            }}
          >
            <Paragraph sx={{ fontWeight: "bold", color: "error.500" }}> {t("Reason for Errors")}</Paragraph>
            <Box sx={{ fontSize: 0 }}>
              {currentErrorMessage ? ReactHtmlParser(currentErrorMessage) : t("No Errors")}
            </Box>
          </Box>
          <Paragraph sx={{ mt: 3, mb: 1, fontSize: 2, fontWeight: "bold" }}> {t("Update the reasoning")}</Paragraph>

          <Editor theme="snow" value={editReasons} onChange={setEditReasons} />
          <Flex
            sx={{
              mt: 3,
              justifyContent: "flex-end",
              gap: 2,
            }}
          >
            <Button
              variant="error"
              onClick={() => {
                onConfirm(ApprovalStatus.Rejected);
              }}
              disabled={submitting}
              aria-label={t("reject entity")}
            >
              {t("Reject")}
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                onConfirm(ApprovalStatus.Pending);
              }}
              disabled={submitting}
              aria-label={t("move entity to pending")}
            >
              {t("Move to Pending")}
            </Button>
            {submitting && <IconLoading size="small" />}
          </Flex>
        </Box>
      )}
    </Modal>
  );
};
