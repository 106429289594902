import { Box, Flex } from "@powerledger/ui-component-lib";
import { FC, Fragment } from "react";
import { useTranslation } from "react-i18next";

import { SeparatorLayoutProps } from "./separator-layout.types";

export const SeparatorLayout: FC<SeparatorLayoutProps> = ({
  data,
  headingStyle,
  contentStyle,
  wrapperStyle,
  reverse = false,
  showSeparator = true,
}) => {
  const { t } = useTranslation();
  return (
    <Flex
      sx={{
        ...wrapperStyle,
        flexWrap: "wrap",
      }}
    >
      {Object.entries(data).map((entry, index) => {
        const header = reverse ? (typeof entry[1] !== "object" ? t(String(entry[1])) : entry[1]) : t(entry[0]);
        const description = reverse ? t(entry[0]) : typeof entry[1] !== "object" ? t(String(entry[1])) : entry[1];
        return (
          <Fragment key={index}>
            {showSeparator && (
              <Box
                sx={{
                  borderWidth: "0.5px",
                  borderStyle: "solid",
                  borderColor: "textDarker",
                  mr: "10px",
                }}
              />
            )}
            <Box
              sx={{
                width: "25%",
                mr: 2,
                ...contentStyle,
                overflowWrap: "break-word",
              }}
            >
              <Box sx={{ fontWeight: "bold", fontSize: 2, ...headingStyle }}>{header} </Box>
              <Box sx={{ fontSize: 1 }}>{description}</Box>
            </Box>
          </Fragment>
        );
      })}
    </Flex>
  );
};
