import { IconNext } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";
import { Box, Button, Flex, Heading } from "theme-ui";

import { PageHeaderProps } from "./page-header.types";

export const PageHeader: React.FC<PageHeaderProps> = ({ goBack, subtitle, title, extraContent }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      {goBack && (
        <Button
          sx={{ px: 2, py: 1, justifyContent: "center", position: ["relative", "absolute"] }}
          variant="text"
          onClick={goBack}
        >
          <IconNext
            size={4}
            color="text"
            sx={{
              ml: "-10px",
              transform: "rotate(180deg)",
            }}
          />
          {t("Back")}
        </Button>
      )}
      <Flex
        sx={{
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Heading
          sx={{
            variant: "texts.tracexDefaultHeading",
            fontSize: 5,
            display: "flex",
            alignItems: "flex-end",
            flexWrap: "wrap",
          }}
        >
          {t(title)}
          {subtitle && (
            <Heading
              as="span"
              sx={{
                variant: "texts.tracexDefaultHeading",
                fontSize: 2,
                px: 2,
                verticalAlign: "bottom",
                lineHeight: 1.2,
              }}
            >
              {t(subtitle)}
            </Heading>
          )}
        </Heading>
        {extraContent}
      </Flex>
    </Box>
  );
};
