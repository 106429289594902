import axios, { CanceledError, CancelTokenSource } from "axios";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import notifications from "@/app/container/notifications";
import { blobToDataURI } from "@/app/lib/blob-to-data-uri";
import { DOCUMENT_DECRYPTION_URL } from "@/app/lib/env-helpers";
import { EntityType } from "@/app/types/generated/graphql";

import { ModalContext } from "../modal-provider/modal-provider";
import { DocumentsGridProps, ShowDoc, UseDocumentsGridHook } from "./documents-grid.types";

export const useDocumentsGrid: UseDocumentsGridHook = ({ accountId }) => {
  const source = useRef<CancelTokenSource>();
  const { t } = useTranslation();
  const [viewLoading, setViewLoading] = useState("");

  const { showAccountRequestEditEntityModal } = useContext(ModalContext);

  const [instance, setInstance] = useState<Window>();

  const [showDoc, setShowDoc] = useState<ShowDoc | null>(null);
  const fetchImage = useCallback(
    async (document: DocumentsGridProps["documents"][0]) => {
      try {
        if (source.current) {
          source.current.cancel();
          source.current = undefined;
        }
        setViewLoading(document?.id || "");

        if (!document?.signedUrl) {
          throw new Error();
        }

        source.current = axios.CancelToken.source();

        const { data: response } = await axios.get(DOCUMENT_DECRYPTION_URL, {
          params: {
            url: document?.signedUrl,
          },
          responseType: "blob",
          cancelToken: source.current.token,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        /** If Success set view loading to false
         * Not Done in finally block since we have to conditionally set this in catch block too
         */

        setViewLoading("");

        const dataURL = await blobToDataURI(new Blob([response], { type: document.mediaType ?? "application/png" }));

        setShowDoc({
          image: dataURL ?? "",
          imageName: `${document.holder.type.toLowerCase()}-${document.holder.name}-${document.type}-${document.id}`,
        });
      } catch (e) {
        if (!(e instanceof CanceledError)) {
          /** Only When the response is not cancelled set the view loading to false
           * If the previous request was cancelled, that means another request has taken place and viewLoading is already overwritten in try block
           */
          setViewLoading("");
          notifications.error({
            description: t("Cannot fetch the image"),
          });
        }
      } finally {
        source.current = undefined;
      }
    },
    [t],
  );

  useEffect(() => {
    return () => {
      source.current?.cancel();
    };
  }, []);

  useEffect(() => {
    const closeInstance = () => {
      instance?.close();
    };
    window.addEventListener("beforeunload", closeInstance);
    return () => {
      if (instance) instance.close();
      window.removeEventListener("beforeunload", closeInstance);
    };
  }, [instance]);

  const getWindowInstance = useCallback((window) => {
    setInstance(window);
  }, []);

  const closePopup = useCallback(() => {
    setShowDoc(null);
  }, []);

  const openRequestEditModal = useCallback(
    (document: DocumentsGridProps["documents"]["0"]) => {
      showAccountRequestEditEntityModal({
        accountId,
        id: document.approvalState?.id,
        entityType: EntityType.Document,
        title: `Request Edit Document (${document.holder.name} - ${document.type}.${document.mediaType})`,
      });
    },
    [accountId, showAccountRequestEditEntityModal],
  );

  return {
    showDoc,
    closePopup,
    getWindowInstance,
    viewLoading,
    fetchImage,
    openRequestEditModal,
  };
};
