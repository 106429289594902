import { Button, Chip, Flex, Table, Tooltip } from "@powerledger/ui-component-lib";
import { startCase } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, Row } from "react-table";

import { TableValueWrapper } from "@/app/components/table-value-wrapper";
import { AppDateFormats, formatDate } from "@/app/lib/format-date";
import { getTableTranslation } from "@/app/lib/get-translations-for-components";
import { volumeFormatter } from "@/app/lib/volume-formatter";
import { ExportRequestStatus } from "@/app/types/generated/graphql";

import { ExportRequestsListTableData, ExportRequestsListTableProps } from "../export-request-list.types";

const chipVariant = {
  [ExportRequestStatus.Exporting]: "chips.primary",
  [ExportRequestStatus.Failed]: "chips.error",
  [ExportRequestStatus.Pending]: "chips.info",
  [ExportRequestStatus.PartialSuccess]: "chips.info",
  [ExportRequestStatus.Success]: "chips.info",
} as const;

export const ExportStatusChip = ({
  id,
  statusMessage,
  status,
}: {
  statusMessage?: string | null;
  id: string;
  status: ExportRequestStatus;
}) => {
  const { t } = useTranslation();

  return (
    <Flex>
      <Chip
        sx={{
          variant: chipVariant[status],
        }}
        label={t(status)}
      />
      {statusMessage && <Tooltip id={id} content={<>{t(statusMessage)}</>} />}
    </Flex>
  );
};

export default function ExportRequestsListTable({
  data,
  loading,
  pageInfo,
  fetchData,
  onSeeDetails,
}: Readonly<ExportRequestsListTableProps>) {
  const { t } = useTranslation();
  const columns: Column<ExportRequestsListTableData>[] = useMemo(
    () => [
      {
        Header: t("Export Number"),
        disableSortBy: true,
        accessor: "exportNumber",
        Cell: ({ value }: { value: string }) => <TableValueWrapper value={value} />,
      },
      {
        Header: t("Requested At"),
        accessor: "requestedOn",
        Cell: ({ value }: { value: string }) => (
          <TableValueWrapper
            value={formatDate(value, { formatStyle: AppDateFormats.AbbreviatedMonthFormatWithTime })}
            containerSx={{ minWidth: 80 }}
          />
        ),
      },
      {
        Header: t("Quantity"),
        accessor: "totalVolume",
        Cell: ({ value }: { value: number }) => <TableValueWrapper value={volumeFormatter(value)} />,
      },
      {
        Header: t("initiated By"),
        accessor: "initiatedBy",
        Cell: ({ value }: { value: string }) => <TableValueWrapper value={startCase(value.toLowerCase())} />,
      },
      {
        Header: t("Status"),
        accessor: "status",
        Cell: ({ row, value }: { row: Row<ExportRequestsListTableData>; value: ExportRequestStatus }) => (
          <ExportStatusChip status={value} id={row.id} />
        ),
      },
      {
        Header: t("Action"),
        id: "action",
        Cell: ({ row }: { row: Row<ExportRequestsListTableData> }) => (
          <Flex>
            <Button
              variant="pill.compactSecondary"
              sx={{ py: 1, mr: 2 }}
              aria-label={t("See details for export request {{id}}", { id: row.original.exportNumber })}
              onClick={() => {
                onSeeDetails(row.original.id);
              }}
            >
              {t("See Details")}
            </Button>
          </Flex>
        ),
      },
    ],
    [t, onSeeDetails],
  );

  return (
    <Table
      loading={loading}
      showScrollArrows
      manualPagination
      columns={columns}
      dataSource={data}
      fetchData={fetchData}
      pageInfo={pageInfo}
      translation={getTableTranslation(t)}
    />
  );
}
