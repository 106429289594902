import { Box, Button, Flex, Text } from "@powerledger/ui-component-lib";
import { startCase } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { approvalStatusMap } from "@/app/pages/accounts/account-details/use-account-details";
import { ApprovalStatus, EntityType } from "@/app/types/generated/graphql";

import { DocumentBlock } from "../document-block";
import { DocumentPopUp } from "./document-popup";
import { DocumentsGridViewProps } from "./documents-grid.types";
export const DocumentsGridView: React.FC<DocumentsGridViewProps> = ({
  fetchImage,
  header,
  hideView = false,
  documents,
  showDoc,
  closePopup,
  getWindowInstance,
  viewLoading,
  openRequestEditModal,
  showRequestForEditButton,
  accountId,
}) => {
  const { t } = useTranslation();
  const renderDocumentsGrid = useMemo(() => {
    const documentBlocks: React.ReactNode[] = [];
    if (!documents?.length) {
      return [
        <Box
          key={0}
          sx={{
            fontSize: 2,
          }}
        >
          {t("No Documents Found. Please refetch to query again")}
        </Box>,
      ];
    }
    for (const document of documents) {
      const statusMap = approvalStatusMap[document.approvalState?.status as ApprovalStatus];
      documentBlocks.push(
        <Box key={document.id} sx={{ p: 2, flex: "1 0 250px" }}>
          <DocumentBlock
            sx={{
              div:
                statusMap === "error"
                  ? { color: "error.500", borderColor: "error.500" }
                  : statusMap === "info"
                  ? { color: "blueGray.500", borderColor: "blueGray.500" }
                  : {},
            }}
            onView={async () => {
              await fetchImage(document);
            }}
            extraContents={
              showRequestForEditButton({
                id: document.id,
                accountId,
                status: document.approvalState?.status,
                entity: EntityType.Document,
              }) ? (
                <Button
                  variant="error"
                  sx={{
                    p: 1,
                    mt: 3,
                    fontSize: 0,
                  }}
                  onClick={() => {
                    openRequestEditModal(document);
                  }}
                  aria-label={t("Request edit {{type}} {{name}}'s document", {
                    name: document.holder.name,
                    type: startCase(document.holder.type.toLowerCase()).toLowerCase(),
                  })}
                >
                  {t("Request Edit")}
                </Button>
              ) : undefined
            }
            viewLoading={viewLoading == document?.id}
            hideView={hideView}
            documentHolderDetails={
              <Text
                sx={{
                  fontSize: 1,
                  textAlign: "center",
                  fontWeight: "bolder",
                }}
              >
                {t("{{type}} - {{name}}", {
                  name: t(document.holder.name),
                  type: t(startCase(document.holder.type.toLowerCase())),
                })}
              </Text>
            }
            requiredDocument={document}
          />
        </Box>,
      );
    }
    return documentBlocks;
  }, [documents, accountId, fetchImage, t, openRequestEditModal, showRequestForEditButton, viewLoading, hideView]);

  return (
    <Box
      sx={{
        gap: 3,
        background: "background",
        zIndex: 1,
        borderRadius: 25,
        p: 3,
      }}
    >
      {header}
      <Flex sx={{ flexWrap: "wrap" }}>{renderDocumentsGrid}</Flex>
      {showDoc && (
        <DocumentPopUp
          image={showDoc.image}
          imageName={showDoc.imageName}
          closePopup={closePopup}
          getWindowInstance={getWindowInstance}
        />
      )}
    </Box>
  );
};
