import { find, flatten, map } from "lodash";

import { OrderPosition, RecAssetAttributes, RecOrderAttributes } from "../types/generated/graphql";
import { FormattedOption, RECOrderAttributeOptions } from "./format-rec-options";
import { getVintageHalfText } from "./get-vintage-half-text";
import i18n from "./i18n";

export type CertificationAndEliglibilityType = {
  label: string;
  value: string[];
};

export const CRS = "CRS";
export const CERTIFICATIONS = "certifications";
export const ELIGIBILITIES = "eligibilities";
export type ReturnTypeFormatAttributes = {
  certifications: string[];
  eligibilities: string[];
  fuelSources: string[];
  location: string;
  project: string;
  vintage: string;
  certificationsAndEligibilities: CertificationAndEliglibilityType[];
};

export const noAssetAttributesFoundErrorMessage = "No asset attributes found!";

export const formatAttributes = ({
  attributes,
  filteredEligibility,
  position,
  options,
}: {
  attributes: RecOrderAttributes | RecAssetAttributes;
  filteredEligibility?: string;
  position?: OrderPosition;
  options?: RECOrderAttributeOptions;
}): ReturnTypeFormatAttributes => {
  if (!options) throw new Error(i18n.t(noAssetAttributesFoundErrorMessage));
  const certifications: string[] =
    map(attributes.certifications, (e) => find(options.certificationOptions, { value: e })?.label || "") || [];
  const defaultValue = position === OrderPosition.Bid ? "Any" : "-";
  const eligibilities: string[] =
    map(
      attributes.eligibilities?.slice().sort((x?: string) => (x === filteredEligibility ? -1 : 1)),
      (e) => find(options.eligibilityOptions, { value: e })?.label || "",
    ) || [];

  const location = (() => {
    const loc: string[] = [];
    const innerOptions: FormattedOption[] = [];
    // Push the nested options outside (Only One level of nesting can occur for now)
    for (const option of options.locationOptions) {
      if (option.options) innerOptions.push(option.options);
    }

    //Flatten the option
    const flattenOption = flatten(innerOptions);

    for (const option of flattenOption) {
      //Check if option value equals to provided location
      const opt = option.value === attributes.location;
      if (opt) {
        loc.push(option.label);
      }
    }
    return loc;
  })();

  const vintage = (() => {
    const yearValue = options.vintageOptions.find((v) => v.value === attributes.vintage?.toString())?.label ?? "";
    return yearValue + getVintageHalfText(attributes.vintageHalf, yearValue ? "-" : "");
  })();

  return {
    vintage,
    eligibilities,
    location: location?.[0] || defaultValue,
    fuelSources: attributes?.fuelSources || [],
    project: find(options.projectOptions, (p) => p.value === attributes.project)?.label || defaultValue,
    certifications,
    certificationsAndEligibilities: [
      {
        label: CERTIFICATIONS,
        value: certifications,
      },
      {
        label: ELIGIBILITIES,
        value: eligibilities,
      },
    ],
  };
};
