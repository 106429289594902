import { Table } from "@powerledger/ui-component-lib";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, Row } from "react-table";
import { Box, Flex } from "theme-ui";

import { AttributesTooltip, LoadingOverlayIndicator, SeparatorLayout } from "@/app/components";
import { EligibilityValueFormatter } from "@/app/components/eligibility-value-formatter";
import { PageHeader } from "@/app/components/page-header";
import { RefreshIconButton } from "@/app/components/refresh-icon-button";
import { StatusInfo } from "@/app/components/retirement-export-requests-common";
import { TableValueWrapper } from "@/app/components/table-value-wrapper";
import { getCommonRecTableColumns } from "@/app/lib/common-rec-table-columns";
import { CertificationAndEliglibilityType } from "@/app/lib/format-attributes";
import { getTableTranslation } from "@/app/lib/get-translations-for-components";
import { volumeFormatter } from "@/app/lib/volume-formatter";
import { ExportRequestStatus, OrderPosition } from "@/app/types/generated/graphql";

import { formatExportReport, FormattedExportSummary } from "../common/helpers";
import { ExportStatusChip } from "../export-request-list/export-request-table/export-requests-table";
import { CreateColumns, ExportRequestDetailsViewProps } from "./export-request-details.types";

const createColumns = ({ t, recOptions }: CreateColumns): Column<FormattedExportSummary>[] => [
  {
    Header: t("Vintage"),
    accessor: "vintage",
    Cell: ({ value }: { value: string }) => <TableValueWrapper value={value} />,
    disableSortBy: true,
  },
  {
    Header: t("Project"),
    accessor: "project",
    Cell: ({ value }: { value: string }) => <TableValueWrapper value={t(value) || ""} />,
    disableSortBy: true,
  },
  {
    Header: t("Eligibility"),
    accessor: "eligibilities",
    Cell: ({ value }: { value: CertificationAndEliglibilityType[] }) => (
      <EligibilityValueFormatter certificationsAndEligibilities={value} position={OrderPosition.Ask} />
    ),
    disableSortBy: true,
  },
  ...(getCommonRecTableColumns(t, recOptions) as Column<FormattedExportSummary>[]),
  {
    Header: t("Commencement Date (COD)"),
    accessor: "commencementOperationDate",
    Cell: ({ value }: { value: string }) => {
      const commencementDate = value ?? "-";
      return <TableValueWrapper value={commencementDate} />;
    },
    disableSortBy: true,
  },
  {
    Header: t("Serial Numbers"),
    accessor: "serialNumbers",
    Cell: ({ value }: { value: string }) => <TableValueWrapper value={value} />,
    disableSortBy: true,
  },
  {
    Header: t("Status"),
    accessor: "status",
    disableSortBy: true,
    Cell: ({ value, row }: { row: Row<FormattedExportSummary>; value: ExportRequestStatus }) => (
      <ExportStatusChip status={value} statusMessage={row.original.statusMessage} id={row.id} />
    ),
  },
  {
    Header: t("Quantity"),
    accessor: "volume",
    Cell: ({ value }: { value: number }) => <TableValueWrapper value={volumeFormatter(value)} />,
    disableSortBy: true,
  },
];

export const ExportRequestDetailsView: React.FC<ExportRequestDetailsViewProps> = ({
  data,
  loading,
  goBack,
  refetchDetails,
  recOptions,
}) => {
  const { t } = useTranslation();
  const columns = useMemo(() => createColumns({ t, recOptions }), [t, recOptions]);

  if (loading || !data) {
    return <LoadingOverlayIndicator />;
  }

  const message = data.statusMessage ?? `-`;

  return (
    <>
      <PageHeader
        goBack={goBack}
        title={`Export Request - ${data.exportNumber}`}
        extraContent={
          <Flex
            sx={{
              alignItems: "center",
              position: "absolute",
              right: 0,
            }}
          >
            <RefreshIconButton fetchData={refetchDetails} loading={loading} />
          </Flex>
        }
      />
      <Box sx={{ mt: 5 }}>
        <SeparatorLayout
          contentStyle={{
            width: "18%",
          }}
          headingStyle={{
            color: "textDark",
          }}
          data={{
            Status: <ExportStatusChip id={data.id} status={data.status} statusMessage={""} />,
            "Status Info": <StatusInfo message={message} />,
          }}
        />
      </Box>

      <Box sx={{ my: 4 }}>
        <Table
          translation={getTableTranslation(t)}
          pagination={false}
          columns={columns}
          dataSource={formatExportReport(data ?? [], recOptions)}
          showScrollArrows
        />
      </Box>

      <AttributesTooltip />
    </>
  );
};
