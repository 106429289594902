import { Box, Flex, IconButton, IconFastForward, Link, Menu } from "@powerledger/ui-component-lib";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { getSupportEmail } from "@/app/lib/get-support-email";

import { TraceXAdminLogo } from "../../tracex-admin-logo";
import { TraceXLogoCircle } from "../../tracex-logo-circle";
import { LayoutSiderProps } from "./layout-sider.types";

export const LayoutSider: FC<LayoutSiderProps> = ({ sider, sidebarRoutes, sidebarOpen, updateSidebarState }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const supportEmail = getSupportEmail();

  return (
    sider || (
      <Flex
        as="aside"
        sx={{
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Box>
          <Flex
            sx={{
              flexDirection: sidebarOpen ? "row" : "column",
              height: sidebarOpen ? 80 : 100,
              alignItems: "center",
              textAlign: "center",
              px: sidebarOpen ? 4 : 0,
              pt: 2,
              ...(sidebarOpen ? {} : { justifyContent: "space-between" }),
            }}
          >
            <IconButton
              sx={{
                width: "100%",
                ...(sidebarOpen
                  ? {
                      mr: 2,
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }
                  : { px: 2 }),
              }}
              onClick={() => navigate("/market")}
            >
              {sidebarOpen && (
                <Box sx={{ width: 5 }}>
                  <TraceXAdminLogo />
                </Box>
              )}
              {!sidebarOpen && <TraceXLogoCircle />}
            </IconButton>
            <IconButton
              sx={{
                flexShrink: 0,
                color: "text",
                "svg path": {
                  fill: "text",
                },
                ...(sidebarOpen ? {} : { width: "100%" }),
              }}
              onClick={() => updateSidebarState()}
            >
              <IconFastForward
                size={5}
                color="text"
                sx={{
                  transition: "base",
                  display: ["none", "none", "flex"],
                  ...(sidebarOpen ? { transform: "rotate(180deg)" } : {}),
                }}
              />
            </IconButton>
          </Flex>
          <Box sx={{ mt: 3 }}>
            <Menu>{sidebarRoutes}</Menu>
          </Box>
        </Box>
        {!sidebarOpen && (
          <Flex
            sx={{
              fontSize: 0,
              color: "text",
              justifyContent: "center",
            }}
          >
            <Box>{t(`©{{date}}`, { date: new Date().getFullYear() })}</Box>
          </Flex>
        )}
        {sidebarOpen && (
          <Box sx={{ fontSize: 0, m: "0 auto", color: "text", mt: 4 }}>
            <Link
              href={`mailto:${supportEmail}`}
              target="_blank"
              sx={{
                color: "text",
                textDecoration: "none",
                "&:hover": { textDecoration: "underline" },
              }}
            >
              {t("Contact Us")}
            </Link>
            <Box>{t(`©{{date}} Powerledger. All rights reserved`, { date: new Date().getFullYear() })}</Box>
          </Box>
        )}
      </Flex>
    )
  );
};
