import { IconLoading } from "@powerledger/ui-component-lib";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { Button, Flex, Grid } from "theme-ui";
import * as Yup from "yup";

import { AccountsSelect, Form, FormFieldLabel, FormInput } from "@/app/components";
import { AccountStatus, AccountType } from "@/app/types/generated/graphql";

import { CommoditiesExportViewProps, ExportCommoditiesFormValues } from "./commodities-export.types";

export const CommoditiesExportView: React.FC<CommoditiesExportViewProps> = ({
  confirmCommodityExport,
  initialFormState,
}) => {
  const { t } = useTranslation();
  const { initialValues, ...formState } = initialFormState || {};

  return (
    <Flex
      sx={{
        mt: 4,
        mb: 2,
        bg: "background",
        borderRadius: "8px",
        p: 3,
      }}
    >
      <Formik
        initialValues={initialValues || ({} as ExportCommoditiesFormValues)}
        {...formState}
        enableReinitialize
        onSubmit={confirmCommodityExport}
        validationSchema={Yup.object().shape({
          serialIndexFrom: Yup.number().min(0).required(),
          serialIndexTo: Yup.number()
            .min(0, t("Required"))
            .test(
              "minSerialIndex",
              t("Ending Index should be more than or equal to starting index"),
              (value, { parent }) => {
                return parent.serialIndexFrom > -1 ? (value || 0) >= parent.serialIndexFrom : true;
              },
            )
            .required("Required"),
          serialPrefix: Yup.string().required(),
          tradingAccount: Yup.object()
            .shape({
              label: Yup.string().required(),
              value: Yup.string().required(),
            })
            .required(),
        })}
      >
        {({
          values,
          isValid,
          setFieldValue,
          handleSubmit,
          isSubmitting,
          errors,
          getFieldProps,
          touched,
          setFieldTouched,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Grid gap={3} columns={[2, null, 3]} sx={{ alignItems: "baseline" }}>
                <Form.Item key="commodities-export-prefix" sx={{ justifyContent: "end" }}>
                  <FormFieldLabel hasErrorMessage={false} small name="serialPrefix" label={t("Serial Prefix *")}>
                    <FormInput hasErrorIndicator={false} {...getFieldProps("serialPrefix")} />
                  </FormFieldLabel>
                </Form.Item>
                <Form.Item key="commodities-export-start" sx={{ justifyContent: "end" }}>
                  <FormFieldLabel hasErrorMessage={false} small name="serialIndexFrom" label={t("Starting Index *")}>
                    <FormInput debounce hasErrorIndicator={false} name="serialIndexFrom" />
                  </FormFieldLabel>
                </Form.Item>
                <Form.Item key="commodities-export-end" sx={{ justifyContent: "end" }}>
                  <FormFieldLabel
                    hasErrorMessage={errors.serialIndexTo !== "Required"}
                    small
                    name="serialIndexTo"
                    label={t("Ending Index *")}
                  >
                    <FormInput type="number" name="serialIndexTo" hasErrorIndicator={false} />
                  </FormFieldLabel>
                </Form.Item>

                <Form.Item key="commodities-export-user" sx={{ justifyContent: "end" }}>
                  <FormFieldLabel
                    hasErrorMessage={false}
                    small
                    name="tradingAccount"
                    label={t("Trading Account *")}
                    sx={{
                      ...(errors?.tradingAccount && touched?.tradingAccount
                        ? {
                            'div[class*="container"]>div': {
                              borderWidth: "1px",
                              borderColor: "warning",
                              borderStyle: "solid",
                            },
                          }
                        : {}),
                    }}
                  >
                    <AccountsSelect
                      onFieldChange={(option) => {
                        setFieldValue("tradingAccount", option);
                      }}
                      onBlur={() => {
                        setFieldTouched("tradingAccount", true, true);
                      }}
                      filter={{ status: AccountStatus.Active, type: AccountType.Registry }}
                      selectedValue={values.tradingAccount}
                    />
                  </FormFieldLabel>
                </Form.Item>
              </Grid>

              <Flex
                sx={{
                  justifyContent: "flex-end",
                  gap: 2,
                }}
              >
                <Button type="submit" variant="primary" disabled={!isValid || isSubmitting}>
                  {t("Submit")}
                </Button>
                {isSubmitting && <IconLoading />}
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </Flex>
  );
};
