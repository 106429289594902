import { useOktaAuth } from "@okta/okta-react";
import { useMemo } from "react";

import { UserRoles } from "@/app/lib/role-based-access-control";

export const useOktaRoles = () => {
  const okta = useOktaAuth();
  const userRoles = useMemo(() => {
    const oktaRole = okta.oktaAuth?.authStateManager.getAuthState()?.accessToken?.claims?.roles;
    if (!oktaRole) {
      return [];
    } else if (!Array.isArray(oktaRole)) {
      return [oktaRole] as UserRoles[];
    }
    return oktaRole as UserRoles[];
  }, [okta]);

  return {
    userRoles,
  };
};
