import { format } from "date-fns";

export enum AppDateFormats {
  UniversalDateTimeFormatWithOffset = "dd/MM/yyyy p z",
  UniversalDateTimeFormat = "dd/MM/yyyy p",
  UniversalYearMonthFormat = "MM/yyyy",
  UniversalDateFormat = "dd/MM/yyyy",
  YearFormat = "yyyy",
  AbbreviatedMonthFormat = "dd MMM yyyy",
  AbbreviatedMonthFormatWithTime = "dd MMM yyyy p",
}

export const onChangeDateFormats = {
  [AppDateFormats.UniversalDateTimeFormat]: "yyyy-MM-dd p",
  [AppDateFormats.YearFormat]: "yyyy",
  [AppDateFormats.UniversalYearMonthFormat]: "yyyy-MM",
  [AppDateFormats.UniversalDateTimeFormatWithOffset]: "yyyy-MM-dd p z",
  [AppDateFormats.UniversalDateFormat]: "yyyy-MM-dd",
  [AppDateFormats.AbbreviatedMonthFormat]: "dd MMM yyyy",
  [AppDateFormats.AbbreviatedMonthFormatWithTime]: "dd MMM yyyy p",
};

export const formatDate = (
  date?: string | null,
  opts?: {
    formatStyle?: AppDateFormats;
    noTZ?: boolean;
    noOffset?: boolean;
  },
) => {
  const { noTZ = false, noOffset = false, formatStyle = AppDateFormats.UniversalDateFormat } = opts || {};
  if (date) {
    const offsettedDate = getDateBasedOnOffset(date, noOffset) ?? Date.parse(date);
    return `${format(offsettedDate, formatStyle)} ${!noTZ ? Intl.DateTimeFormat().resolvedOptions().timeZone : ""}`;
  }
  return "-";
};

/** GMT TimeOffset is from -12 to +14
 * Baker Island (-12) - Samoa (Apia) (-11) (** If you can't find Baker Island in your timezone settings)
 * Kiribati (+14)
 */
export const getDateBasedOnOffset = (value?: string, noOffset?: boolean) => {
  if (!value) return null;

  const date = new Date(value);

  if (!noOffset) return date;
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() + userTimezoneOffset);
};
