import React from "react";

type Props = {
  animate?: boolean;
};

type State = {
  levels: number[];
};

class LogoCarbonDeviceSVG extends React.Component<Props, State> {
  static defaultProps: Partial<Props>;
  timerID?: NodeJS.Timer;

  constructor(props: Props) {
    super(props);
    this.state = {
      levels: [0.18, 0.23, 0.28, 0.33, 0.38, 0.43, 0.48, 0.53, 0.58, 0.63, 0.68, 0.73].reverse(),
    };
  }

  componentDidMount() {
    if (this.props.animate) {
      this.timerID = setInterval(() => this.updateOpacity(), 100);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.animate !== this.props.animate) {
      clearInterval(this.timerID);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  updateOpacity() {
    this.setState((prevState) => {
      const newLevels = prevState.levels;
      newLevels.forEach(function (item, index) {
        if (index >= newLevels.length - 1) {
          newLevels[index] = newLevels[0];
        } else {
          newLevels[index] = newLevels[index + 1];
        }
      });
      return {
        levels: newLevels,
      };
    });
  }

  render() {
    return (
      <svg width="50px" height="50px" viewBox="0 0 62 61" role="img">
        <path
          fill={"var(--theme-ui-colors-text)"}
          opacity={this.state.levels[11]}
          d="M32.0278 7.82151L46.005 4.09932L49.7502
        17.9917L35.7727 21.7142L32.0278 7.82151Z"
        />
        <path
          fill={"var(--theme-ui-colors-text)"}
          opacity={this.state.levels[10]}
          d="M39.5059 25.4035L43.2508 11.5108L57.2282
        15.2333L53.483 29.1257L39.5059 25.4035Z"
        />
        <path
          fill={"var(--theme-ui-colors-text)"}
          opacity={this.state.levels[9]}
          d="M40.8994 30.4907L51.1317 20.3205L61.3643
        30.4907L51.1317 40.6609L40.8994 30.4907Z"
        />
        <path
          fill={"var(--theme-ui-colors-text)"}
          opacity={this.state.levels[8]}
          d="M39.5225 35.5686L53.4999 31.8464L57.2448
        45.7388L43.2677 49.4613L39.5225 35.5686Z"
        />
        <path
          fill={"var(--theme-ui-colors-text)"}
          opacity={this.state.levels[7]}
          d="M32.0381 53.1814L35.7833 39.2887L49.7605
        43.0112L46.0156 56.9036L32.0381 53.1814Z"
        />
        <path
          fill={"var(--theme-ui-colors-text)"}
          opacity={this.state.levels[6]}
          d="M20.4546 50.8208L30.6869 40.6506L40.9191
        50.8208L30.6869 60.991L20.4546 50.8208Z"
        />
        <path
          fill={"var(--theme-ui-colors-text)"}
          opacity={this.state.levels[5]}
          d="M11.5786 43.0253L25.5561 39.3031L29.301
        53.1955L15.3235 56.918L11.5786 43.0253Z"
        />
        <path
          fill={"var(--theme-ui-colors-text)"}
          opacity={this.state.levels[4]}
          d="M4.09863 45.7313L7.84354 31.8386L21.821
        35.5611L18.0761 49.4535L4.09863 45.7313Z"
        />
        <path
          fill={"var(--theme-ui-colors-text)"}
          opacity={this.state.levels[3]}
          d="M0 30.4997L10.2323 20.3295L20.4648
        30.4997L10.2323 40.6699L0 30.4997Z"
        />
        <path
          fill={"var(--theme-ui-colors-text)"}
          opacity={this.state.levels[2]}
          d="M4.104 15.2428L18.0815 11.5206L21.8264
        25.413L7.84891 29.1355L4.104 15.2428Z"
        />
        <path
          fill={"var(--theme-ui-colors-text)"}
          opacity={this.state.levels[1]}
          d="M11.5874 17.989L15.3323 4.09627L29.3098
        7.81875L25.5649 21.7112L11.5874 17.989Z"
        />
        <path
          fill={"var(--theme-ui-colors-text)"}
          opacity={this.state.levels[0]}
          d="M20.4453 10.1702L30.6776 0L40.9101
        10.1702L30.6776 20.3404L20.4453 10.1702Z"
        />
      </svg>
    );
  }
}

LogoCarbonDeviceSVG.defaultProps = {
  animate: true,
};

export default LogoCarbonDeviceSVG;
