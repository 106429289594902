import { Box, Flex, LegacySelect, Table } from "@powerledger/ui-component-lib";
import { startCase } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, Row } from "react-table";

import { Input } from "@/app/components";
import { TableActionLinkButton } from "@/app/components/table-action-buttons";
import { TableActionDropdown } from "@/app/components/table-action-buttons/table-action-dropdown";
import { getSelectTranslation, getTableTranslation } from "@/app/lib/get-translations-for-components";

import { ManagedCommoditiesLocationState } from "../../managed-commodities/managed-commodities.types";
import { AccountDetailsLocationState } from "../account-details/account-details.types";
import { hasPersonalRegistries } from "../account-details/use-account-details";
import { AccountsTableViewProps, AccountTableData } from "./accounts-table.types";
import { accountStatusOptions, accountTypeOptions } from "./use-accounts-table";

export const AccountsTableView: React.FC<AccountsTableViewProps> = ({
  accountsQuery,
  loading,
  onAccountsSearch,
  searchTerms,
  pageInfo,
  fetchData,
}) => {
  const { t } = useTranslation();

  const tableColumns: Column<AccountTableData>[] = useMemo(
    () => [
      {
        Header: t("Account ID"),
        accessor: (row) => row?.id,
        disableSortBy: true,
        Cell: ({ value }: { value: string }) => (
          <Flex sx={{ color: "textDarker", whiteSpace: "break-spaces", maxWidth: 350 }}>{value || "-"}</Flex>
        ),
      },
      {
        Header: t("Business Name"),
        accessor: (row) => row?.company?.businessName,
        id: "businessName",
        Cell: ({ value }: { value: string }) => <Flex sx={{ color: "textDarker" }}>{value || "-"}</Flex>,
      },
      {
        Header: t("Email"),
        accessor: "email",
        id: "email",
        Cell: ({ value }: { value: string }) => (
          <Flex
            sx={{
              color: "textDarker",
            }}
          >
            {value || "-"}
          </Flex>
        ),
      },
      {
        Header: t("Account Type"),
        accessor: "type",
        id: "type",
        Cell: ({ value }: { value: string }) => (
          <Flex
            sx={{
              color: "textDarker",
            }}
          >
            {startCase(value?.toLowerCase() ?? "-")}
          </Flex>
        ),
      },
      {
        Header: t("Action"),
        id: "See-Details",
        maxWidth: 100,
        Cell: ({ row }: { row: Row<AccountTableData> }) => (
          <TableActionDropdown
            actions={[
              <TableActionLinkButton
                key={row.original.id + row.index + 1}
                linkProps={
                  { to: `/accounts/${row.original.id}`, state: { searchKeys: searchTerms } } satisfies {
                    to: string;
                    state: AccountDetailsLocationState;
                  }
                }
                text={t("See Details")}
              />,
              ...(!hasPersonalRegistries(row.original.type)
                ? [
                    <TableActionLinkButton
                      key={row.original.id + row.index + 2}
                      linkProps={
                        {
                          to: `${row.original.id}/managed-commodities/`,
                          state: { searchKeys: searchTerms, companyName: row.original.company?.businessName },
                        } satisfies {
                          to: string;
                          state: ManagedCommoditiesLocationState;
                        }
                      }
                      text={t("See Managed Commodities")}
                    />,
                  ]
                : []),
            ]}
          />
        ),
      },
    ],
    [t, searchTerms],
  );
  return (
    <>
      <Flex
        sx={{
          py: 3,
          mb: 3,
          width: "100%",
          gap: 2,
          flexWrap: "wrap",
          flexDirection: ["column", "row"],
        }}
      >
        <Box
          sx={{
            flex: 1,
          }}
        >
          <Input
            onInput={(event) => onAccountsSearch((event.target as HTMLInputElement).value, "companyName")}
            placeholder="Enter company name to search..."
            defaultValue={searchTerms?.companyName}
            sx={{
              background: "background",
            }}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
          }}
        >
          <Input
            onInput={(event) => onAccountsSearch((event.target as HTMLInputElement).value, "id")}
            placeholder="Enter account id to search..."
            defaultValue={searchTerms?.id}
            sx={{
              background: "background",
            }}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
          }}
          aria-label="filter account status"
        >
          <LegacySelect
            sx={{
              [`div[class*="container"]>div`]: {
                bg: "background",
              },
              [`div[class*="menu"]>div`]: {
                bg: "background",
              },
            }}
            value={accountTypeOptions.find((option) => option.value === searchTerms.accountType)}
            options={accountTypeOptions}
            translation={getSelectTranslation(t)}
            onChange={(option) => {
              onAccountsSearch(option?.value ?? "", "accountType");
            }}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
          }}
          aria-label="filter account status"
        >
          <LegacySelect
            sx={{
              [`div[class*="container"]>div`]: {
                bg: "background",
              },
              [`div[class*="menu"]>div`]: {
                bg: "background",
              },
            }}
            value={accountStatusOptions.find((option) => option.value === searchTerms.status)}
            options={accountStatusOptions}
            translation={getSelectTranslation(t)}
            onChange={(option) => {
              onAccountsSearch(option?.value || "", "status");
            }}
          />
        </Box>
      </Flex>
      <Table
        columns={tableColumns}
        dataSource={accountsQuery?.paginatedAccounts?.accounts || []}
        pageInfo={pageInfo}
        containerSx={{
          table: {
            thead: {
              tr: {
                "th:last-of-type": {
                  width: 100,
                },
              },
            },
          },
        }}
        fetchData={fetchData}
        manualPagination
        loading={loading}
        showScrollArrows
        translation={getTableTranslation(t)}
      />
    </>
  );
};
