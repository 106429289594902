import { Box, BoxProps, Flex, FlexProps } from "@powerledger/ui-component-lib";
import { useFormikContext } from "formik";
import { FC, FormEvent } from "react";

const Item: FC<FlexProps> = ({ children, sx, ...props }) => (
  <Flex sx={{ flexDirection: "column", mb: 3, ...sx }} {...props}>
    {children}
  </Flex>
);

type FormBoxProps = Omit<BoxProps, "onSubmit"> & {
  onSubmit?: (e?: FormEvent<HTMLFormElement> | undefined) => void;
};

type FormType = FC<FormBoxProps> & {
  Item: typeof Item;
};

export const Form: FormType = ({ children, onSubmit, sx = {}, ...props }) => {
  const { handleReset, handleSubmit } = useFormikContext();
  return (
    <Box
      as="form"
      onReset={handleReset}
      onSubmit={handleSubmit as any}
      sx={{
        width: "100%",
        flexDirection: "column",
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

Form.Item = Item;
