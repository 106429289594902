import { startCase } from "lodash";

import { CertificationAndEliglibilityType, formatAttributes } from "@/app/lib/format-attributes";
import { RECOrderAttributeOptions } from "@/app/lib/format-rec-options";
import { volumeFormatter } from "@/app/lib/volume-formatter";
import {
  CommodityTransactionStatus,
  RecAssetAttributes,
  RetirementByIdQuery,
  RetirementCategory,
  RetirementStatus,
} from "@/app/types/generated/graphql";

export type FormattedRetirementSummary = {
  id: string;
  vintage: string;
  project: string;
  eligibilities: CertificationAndEliglibilityType[];
  fuelSources: string[];
  location: string;
  volume: string;
  serialNumbers: string;
  status: CommodityTransactionStatus;
  statusMessage: string;
  commencementOperationDate: string;
  retirementStatus: RetirementStatus;
};

export const formatRetirementReport = (
  retirementDetails: RetirementByIdQuery["retirementById"],
  recOptions?: RECOrderAttributeOptions,
): FormattedRetirementSummary[] => {
  if (!recOptions) return [];
  return retirementDetails.commodityDetails.flatMap((detail) => {
    const attributes = detail.attributes as RecAssetAttributes;
    const formattedAttributes = formatAttributes({
      attributes,
      options: recOptions,
    });
    const commodities =
      detail.commodityTransactions?.map((transaction) => ({
        serialNumbers: `${transaction.commoditySerial.serialPrefix}-${transaction.commoditySerial.serialFrom} to ${transaction.commoditySerial.serialTo}`,
        status: transaction.status,
        statusMessage: transaction.statusMessage ?? "",
        id: transaction.id,
      })) ?? [];
    return commodities.map((commodityInfo) => ({
      vintage: formattedAttributes.vintage,
      project: formattedAttributes.project,
      eligibilities: formattedAttributes.certificationsAndEligibilities,
      fuelSources: attributes.fuelSources || [],
      location: formattedAttributes.location,
      volume: volumeFormatter(detail.volume),
      commencementOperationDate: attributes.commencementDate ?? "-",
      retirementStatus: detail.status,
      ...commodityInfo,
    }));
  });
};

export const getRetirementReason = (retirementDetails: {
  reason: string;
  notes: string;
  compliancePeriod?: string | null;
}) => {
  const reason = retirementDetails.reason;
  const compliancePeriod = retirementDetails.compliancePeriod
    ? `(Compliance Period - ${retirementDetails.compliancePeriod})`
    : "";
  return `${reason} ${compliancePeriod}`.trim();
};

export const getRetirementType = (retirementType: string, retirementCategory: RetirementCategory) => {
  return `${retirementType} (${startCase(retirementCategory.toLowerCase())})`;
};
