import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import { AccountFilter, usePaginatedAccountsLazyQuery } from "../../types/generated/graphql";

export const useAccountsSelect = ({ pageSize = 10, filter }: { filter?: AccountFilter; pageSize?: number }) => {
  const [getAccounts, { data: accountsQuery, loading: loadingAccountsData, previousData }] =
    usePaginatedAccountsLazyQuery();
  const [scrollCursor, setScrollCursor] = useState(1);
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    getAccounts({
      variables: {
        input: {
          offSetPaginationInfo: {
            limit: scrollCursor * pageSize,
            offset: 0,
          },
          where: {
            ...filter,
            company: {
              businessName: searchText || null,
            },
          },
        },
      },
    });
  }, [getAccounts, filter, scrollCursor, pageSize, searchText]);

  const cachedAccountsOptions = useMemo(
    () =>
      (accountsQuery?.paginatedAccounts?.accounts ?? previousData?.paginatedAccounts?.accounts ?? [])?.map(
        (account) => ({
          label: account.company?.businessName || "",
          value: account.id,
        }),
      ) || [],
    [accountsQuery?.paginatedAccounts?.accounts, previousData?.paginatedAccounts?.accounts],
  );

  const getMoreData = useCallback(() => {
    setScrollCursor((cursor) => cursor + 1);
  }, []);

  const onAccountsSearch = useCallback((text = "") => {
    setSearchText(text);
    return true;
  }, []);

  const onCustomSearch = useMemo(() => debounce(onAccountsSearch, 500), [onAccountsSearch]);

  useEffect(() => {
    return () => {
      onCustomSearch.cancel();
    };
  }, [onCustomSearch]);

  const totalAccounts =
    accountsQuery?.paginatedAccounts?.offsetInfo?.total ?? previousData?.paginatedAccounts?.offsetInfo?.total ?? 0;

  return {
    cachedAccountsOptions,
    getMoreData,
    onCustomSearch,
    loadingAccountsData,
    totalAccounts,
  };
};
