import { getColor, IconRefreshCircle, useTheme } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";
import { Button } from "theme-ui";

export function RefreshIconButton({
  loading = false,
  fetchData,
}: Readonly<{ loading?: boolean; fetchData: () => void }>) {
  const { t } = useTranslation();
  const { theme } = useTheme();
  return (
    <Button
      disabled={loading}
      sx={{
        opacity: loading ? 0.5 : 1,
        div: {
          svg: {
            path: { fill: `${getColor(theme, "text")} !important` },
          },
        },
      }}
      variant="text"
      onClick={fetchData}
      aria-label={t("refresh")}
    >
      <IconRefreshCircle size={6} />
    </Button>
  );
}
