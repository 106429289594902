import {
  IconBiogas,
  IconBiomass,
  IconFuelcell,
  IconGas,
  IconGeothermal,
  IconHydro,
  IconHydrogen,
  IconOther,
  IconSolar,
  IconWave,
  IconWind,
} from "@powerledger/ui-component-lib";

export const getFuelSourceIcon = (fuelSourceCode?: string) => {
  switch (fuelSourceCode) {
    case "ARS":
    case "ALT":
    case "TDF":
    case "WHR":
      return IconOther;
    case "BIG":
      return IconBiogas;
    case "BIM":
    case "BM2":
    case "BAF":
    case "BLQ":
    case "RWB":
    case "BSW":
    case "AB1":
    case "AB2":
    case "BAW":
    case "BA3":
    case "BA2":
    case "BA1":
    case "BBW":
    case "BHV":
    case "BID":
    case "BGR":
    case "BBL":
    case "BNV":
    case "PA1":
    case "RDF":
    case "SLW":
    case "BTW":
    case "BUW":
    case "WDR":
    case "WDU":
    case "WDL":
    case "WDS":
    case "CHE":
    case "MS1":
    case "WBS":
      return IconBiomass;
    case "CON":
    case "BFG":
    case "RNG":
      return IconGas;
    case "FC1":
      return IconFuelcell;
    case "GE1":
      return IconGeothermal;
    case "GWS":
    case "HY2":
    case "H2O":
      return IconHydro;
    case "HYD":
      return IconHydrogen;
    case "SO1":
    case "SOT":
      return IconSolar;
    case "OC1":
      return IconWave;
    case "WND":
    case "WD2":
      return IconWind;
    default:
      return null;
  }
};
