import { useTablePaginationManager } from "@powerledger/ui-component-lib";
import { useCallback, useContext, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { ModalContext } from "@/app/components/modal-provider/modal-provider";
import { useRecOptions } from "@/app/hooks/use-rec-options";
import { formatAttributes } from "@/app/lib/format-attributes";
import {
  AssetType,
  RecAssetAttributes,
  RegistryServiceSortOrderInput,
  useAccountsQuery,
  useManagedCommoditiesQuery,
} from "@/app/types/generated/graphql";

import {
  ExportCommodity,
  ManagedCommoditiesData,
  ManagedCommoditiesFilters,
  ManagedCommoditiesLocationState,
  SelectedAccountDetails,
  UseManagedCommoditiesFn,
} from "./managed-commodities.types";

export const useManagedCommodities: UseManagedCommoditiesFn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [filterValues, setFilterValues] = useState<ManagedCommoditiesFilters>({});
  const [selectedAccountDetails, setSelectedAccountDetails] = useState<SelectedAccountDetails>({});
  const [showAllAttributes, setShowAllAttributes] = useState(false);

  const accountId = params.id;

  const { showManagedCommoditiesExportModal } = useContext(ModalContext);

  const hasParamsId = !!params.id;

  const { pageInfo, offset, fetchData, sort, resetPage, setTotalItems } = useTablePaginationManager<
    ManagedCommoditiesData,
    RegistryServiceSortOrderInput[]
  >({});

  const {
    data,
    loading: queryLoading,
    previousData,
    refetch,
  } = useManagedCommoditiesQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        offSetPaginationInfo: {
          offset,
          limit: pageInfo.pageSize,
        },
        sortOrderInputs: sort,
        where: {
          accountId: accountId ?? filterValues?.accountId,
          assetType: AssetType.Rec,
          generatedOn: filterValues.vintage || null,
          serialPrefix: filterValues.serialPrefix || null,
        },
      },
    },
    onCompleted(data) {
      setTotalItems(data?.managedCommodities?.offsetInfo?.total ?? 0);
    },
  });

  const { data: accountQuery } = useAccountsQuery({
    skip: !selectedAccountDetails?.value,
    fetchPolicy: "cache-and-network",
    variables: {
      where: {
        id: selectedAccountDetails?.value,
      },
    },
  });

  const currentData = useMemo(() => data ?? previousData, [data, previousData]);

  const updateSelectedAccountDetails = useCallback((detail: SelectedAccountDetails) => {
    setSelectedAccountDetails(detail);
  }, []);
  const { recOptions, loading: recOptionsLoading, error } = useRecOptions();

  const locationState = location.state as ManagedCommoditiesLocationState;

  const goBack = useCallback(() => {
    navigate(`/accounts`, {
      state: locationState,
    });
  }, [navigate, locationState]);

  if (error) {
    throw error;
  }

  const loading = recOptionsLoading || queryLoading;
  const companyName = locationState?.companyName ?? selectedAccountDetails?.label ?? "";

  const handleFilterChange = useCallback(
    (key, value) => {
      setFilterValues((prev) => ({
        ...prev,
        [key]: value,
      }));
      resetPage();
    },
    [resetPage],
  );

  const tableData = useMemo(() => {
    return !recOptions
      ? []
      : currentData?.managedCommodities?.commodities?.map((commodity) => ({
          ...commodity,
          ...formatAttributes({
            attributes: commodity.attributes as RecAssetAttributes,
            options: recOptions,
          }),
        })) ?? [];
  }, [recOptions, currentData]);

  const refetchData = useCallback(() => {
    refetch();
  }, [refetch]);

  const onExportCommodity = useCallback(
    ({ id, vintage, serial, availableVolume, account, serialFrom, serialPrefix, serialTo }: ExportCommodity) => {
      showManagedCommoditiesExportModal({
        exportData: {
          id,
          vintage,
          serial,
          availableVolume,
          serialFrom,
          serialPrefix,
          serialTo,
          account,
        },
        onNavigate: () => {
          navigate("/export-requests");
        },
      });
    },
    [navigate, showManagedCommoditiesExportModal],
  );

  return {
    goBack,
    loading,
    recOptions,
    tableData,
    refetchData,
    handleFilterChange,
    filterValues,
    companyName,
    fetchData,
    pageInfo,
    updateSelectedAccountDetails,
    selectedAccountDetails,
    hasParamsId,
    accountQuery,
    onExportCommodity,
    showAllAttributes,
    setShowAllAttributes,
  };
};
