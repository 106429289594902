import axios from "axios";

export type ApiGetOptionsType = {
  timeout: number;
};

export type ApiType = {
  get: <T>(path: string, options?: ApiGetOptionsType) => Promise<T>;
  post: <T>(path: string, args?: Record<any, any>, options?: any) => Promise<T>;
  put: <T>(path: string, args?: Record<any, any>, options?: any) => Promise<T>;
};

export const api = (): ApiType => {
  const $axios = axios.create();
  return {
    get: <T>(path: string, options?: ApiGetOptionsType) => $axios.get<T>(path, options).then(({ data }) => data),
    post: <T>(path: string, args?: Record<any, any>, options?: any) =>
      $axios.post<T>(path, args, options).then(({ data }) => data),
    put: <T>(path: string, args?: Record<any, any>, options?: any) =>
      $axios.put<T>(path, args, options).then(({ data }) => data),
  };
};

export default api;
