import { Chip, IconLoading } from "@powerledger/ui-component-lib";
import { format } from "date-fns";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Flex, Heading, Text } from "theme-ui";

import { LoadingOverlayIndicator, SeparatorLayout } from "@/app/components";
import { CreateOrEditTradingHalt } from "@/app/components/create-or-edit-trading-halt";

import { TradingHaltViewProps } from "./trading-halt.types";

export const TradingHaltView: React.FC<TradingHaltViewProps> = ({
  cancelTradingHalt,
  showEditTradingHaltModal,
  tradeHaltData,
  tradeHaltLoading,
  tradeHaltError,
  upcomingTradeHaltsData,
  upComingTradeHaltsLoading,
  tenantsData,
  tenantsLoading,
  upComingTradeHaltsError,
}) => {
  const { t } = useTranslation();
  const renderCurrentTradingHalt = useMemo(() => {
    if (tradeHaltLoading) {
      return <IconLoading size={"normal"} />;
    } else if (tradeHaltError) {
      return <Box sx={{ color: "warning" }}>{t("Error fetching current trading halt.")}</Box>;
    } else if (!tradeHaltData?.currentTradingHalt?.id) {
      return <Text sx={{ fontSize: 2, color: "text" }}>{t("No Current Halt")}</Text>;
    } else {
      return (
        <TradingHaltDetailView
          isApplicable={tradeHaltData?.currentTradingHalt?.isApplicable}
          isPlanned={tradeHaltData?.currentTradingHalt?.isPlanned}
          onEdit={() => {
            showEditTradingHaltModal({
              data: tradeHaltData?.currentTradingHalt,
            });
          }}
          onCancel={async () => await cancelTradingHalt(tradeHaltData?.currentTradingHalt?.id)}
          data={{
            Tenant: tenantsData?.tenants?.find((tenant) => tenant.id === tradeHaltData?.currentTradingHalt?.tenantId)
              ?.displayName,
            "Start DateTime": tradeHaltData?.currentTradingHalt?.startDateTime
              ? format(Date.parse(tradeHaltData?.currentTradingHalt.startDateTime), "yyyy/MM/dd p z") +
                " " +
                Intl.DateTimeFormat().resolvedOptions().timeZone
              : "-",
            "End DateTime": tradeHaltData?.currentTradingHalt?.endDateTime
              ? format(Date.parse(tradeHaltData?.currentTradingHalt.endDateTime), "yyyy/MM/dd p z") +
                " " +
                Intl.DateTimeFormat().resolvedOptions().timeZone
              : "-",
          }}
        />
      );
    }
  }, [
    tradeHaltData,
    tradeHaltLoading,
    cancelTradingHalt,
    tradeHaltError,
    showEditTradingHaltModal,
    t,
    tenantsData?.tenants,
  ]);

  const renderUpcomingTradingHalts = useMemo(() => {
    if (upComingTradeHaltsLoading) {
      return <IconLoading size={"normal"} />;
    } else if (upComingTradeHaltsError) {
      return <Box sx={{ color: "warning" }}>{t("Error fetching upcoming trading halts.")}</Box>;
    } else if (!upcomingTradeHaltsData?.upcomingTradingHalts?.length) {
      return <Text sx={{ fontSize: 2, color: "text" }}>{t("No Upcoming Trading Halts")}</Text>;
    } else {
      return upcomingTradeHaltsData?.upcomingTradingHalts?.map((halt) => (
        <TradingHaltDetailView
          key={halt?.id}
          isApplicable={halt?.isApplicable}
          isPlanned={halt?.isPlanned}
          onEdit={() => {
            showEditTradingHaltModal({
              data: halt,
            });
          }}
          onCancel={async () => {
            await cancelTradingHalt(halt?.id);
          }}
          data={{
            Tenant: tenantsData?.tenants?.find((tenant) => tenant.id === halt?.tenantId)?.displayName,
            "Start DateTime": halt?.startDateTime
              ? format(Date.parse(halt.startDateTime), "yyyy/MM/dd p z") +
                " " +
                Intl.DateTimeFormat().resolvedOptions().timeZone
              : "-",
            "End DateTime": halt?.endDateTime
              ? format(Date.parse(halt.endDateTime), "yyyy/MM/dd p z") +
                " " +
                Intl.DateTimeFormat().resolvedOptions().timeZone
              : "-",
          }}
        />
      ));
    }
  }, [
    upComingTradeHaltsError,
    showEditTradingHaltModal,
    upComingTradeHaltsLoading,
    upcomingTradeHaltsData,
    cancelTradingHalt,
    t,
    tenantsData?.tenants,
  ]);

  if (tenantsLoading) {
    return <LoadingOverlayIndicator />;
  }

  return (
    <Box>
      <Box sx={{ fontSize: 4 }}>
        <Heading sx={{ variant: "texts.tracexDefaultHeading", fontSize: 4 }}>{t("Current Trading Halt")}</Heading>
        {renderCurrentTradingHalt}
      </Box>
      <Box sx={{ fontSize: 4 }}>
        <Heading sx={{ variant: "texts.tracexDefaultHeading", fontSize: 4 }}>{t("Upcoming Trading Halts")}</Heading>
        {renderUpcomingTradingHalts}
      </Box>
      <Box
        sx={{
          mt: 3,
        }}
      >
        <Heading sx={{ variant: "texts.tracexDefaultHeading", fontSize: 4 }}>{t("Create new trading halt")}</Heading>
        <Text
          sx={{
            color: "textDarker",
            fontSize: 1,
          }}
        >
          {t("By default, this will create trading halt for 4 hours from now")}
        </Text>
        <CreateOrEditTradingHalt />
      </Box>
    </Box>
  );
};

const TradingHaltDetailView = ({
  data,
  isApplicable,
  isPlanned,
  onEdit,
  onCancel,
}: {
  data: Record<string, string | number | React.ReactNode>;
  isApplicable?: boolean;
  isPlanned?: boolean;
  onEdit: () => void;
  onCancel: () => Promise<void>;
}) => {
  const { t } = useTranslation();
  return (
    <Flex
      sx={{
        m: 2,
        alignItems: "center",
        bg: "background",
        p: 3,
        flexGrow: 1,
        borderRadius: 10,
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Flex
        sx={{
          gap: 2,
          width: "100%",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Flex
          sx={{
            gap: 2,
          }}
        >
          {isApplicable && (
            <Chip
              label={t("Applicable")}
              sx={{
                variant: "chips.primary",
                padding: "5px",
              }}
            />
          )}
          {isPlanned && (
            <Chip
              label={t("Planned")}
              sx={{
                variant: "chips.primary",
                padding: "5px",
              }}
            />
          )}
        </Flex>
        <Flex
          sx={{
            gap: 2,
          }}
        >
          <Button variant="info" sx={{ py: 1, px: 2 }} onClick={onEdit}>
            {t("Edit")}
          </Button>
          <Button variant="error" sx={{ py: 1, px: 2 }} onClick={onCancel}>
            {t("Delete")}
          </Button>
        </Flex>
      </Flex>
      <SeparatorLayout
        data={data || {}}
        showSeparator={false}
        contentStyle={{
          p: 3,
          textAlign: "left",
          flex: ["", 1],
          width: ["", "100%"],
        }}
        wrapperStyle={{
          width: "100%",
        }}
      />
    </Flex>
  );
};
