import { useOktaAuth } from "@okta/okta-react";
import { FormikHelpers, FormikValues } from "formik";
import { useCallback, useContext } from "react";

import { ModalContext } from "@/app/components/modal-provider/modal-provider";

import { LoginFormValues, UseLoginFn } from "./login.types";

export const useLogin: UseLoginFn = () => {
  const { oktaAuth } = useOktaAuth();
  const { showContactSupportModal } = useContext(ModalContext);

  const handleLogin = useCallback(
    async ({ username, password }: FormikValues, { setStatus }: FormikHelpers<LoginFormValues>) => {
      try {
        const loginTransaction = await oktaAuth.signIn({
          username,
          password,
        });
        await oktaAuth.token.getWithRedirect({
          sessionToken: loginTransaction.sessionToken,
        });
      } catch (error: any) {
        if (error?.xhr?.status !== 401) {
          showContactSupportModal();
        } else setStatus("Sorry, we don't recognise this username & password combination.");
      }
    },
    [oktaAuth, showContactSupportModal],
  );

  return {
    handleLogin,
  };
};
