import { Theme, ThemeUIStyleObject } from "@powerledger/ui-component-lib";

export const CustomThemeProperty = <T extends string>(
  key: T & (T extends keyof Theme ? "Value is reserved by Theme!" : string),
  value: ThemeUIStyleObject,
) => value;

export const toastTracex = {
  ".Toastify__toast": {
    color: "black",
    borderLeftWidth: "10px",
    borderLeftStyle: "solid",
    backgroundColor: "white",
    minWidth: "350px",
    marginLeft: "-30px",
  },
  ".Toastify__toast--info": {
    borderLeftColor: "#605BC8",
  },
  ".Toastify__toast--success": {
    borderLeftColor: "#00B19F",
  },
  ".Toastify__toast--error": {
    borderLeftColor: "#DA1E28",
  },
  ":root ": {
    "--toastify-icon-color-info": "#605BC8",
    "--toastify-icon-color-success": "#00B19F",
    "--toastify-icon-color-error": "#DA1E28",
  },
};

export const fonts = {
  table: "Roboto, MintGroteskV08, Helvetica, Arial, sans-serif",
  body: 'Roboto, "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  heading: "inherit",
  monospace:
    "ui-monospace, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace",
  sans: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
};

export const texts = CustomThemeProperty("texts", {
  tracexDefaultHeading: {
    fontWeight: "600",
    fontSize: 3,
    color: "textDark",
    lineHeight: "30px",
    fontFamily: "inherit",
  },
});

export const themeSwitchOverride = {
  config: {
    initialColorModeName: "",
    useColorSchemeMediaQuery: false,
    useLocalStorage: true,
    printColorModeName: "",
  },
};

export const tracexTheme: Record<string, ThemeUIStyleObject> = {
  config: {
    initialColorModeName: "",
    useColorSchemeMediaQuery: false,
    useLocalStorage: false,
    printColorModeName: "",
  },
  texts,
  fonts,
  styles: {
    root: {
      ...toastTracex,
    },
  },
};
