import { AccountDetailsQuery, AccountDocumentsQuery, EntityType } from "@/app/types/generated/graphql";

export const getCurrentErrorMessage = (
  id: string,
  entityType: EntityType,
  account?: NonNullable<AccountDetailsQuery["paginatedAccounts"]["accounts"]>["0"],
  documents?: NonNullable<AccountDocumentsQuery["paginatedAccounts"]["accounts"]>["0"],
): string => {
  if (entityType === EntityType.Company) {
    return account?.company?.approvalState?.errorMessage ?? "";
  } else if (entityType === EntityType.CompanyContact) {
    return account?.company?.contacts?.find((entity) => entity.id === id)?.approvalState?.errorMessage ?? "";
  }
  const companyDocuments = documents?.company?.documents?.find((doc) => doc.id === id);
  const contactDocuments = documents?.company?.contacts
    ?.flatMap((contact) => contact.documents)
    ?.find((doc) => doc?.id === id);

  return companyDocuments?.approvalState?.errorMessage ?? contactDocuments?.approvalState?.errorMessage ?? "";
};
