import { VintageHalf } from "../types/generated/graphql";
import i18n from "./i18n";

/**
 * Return translated half value with prefix
 * @param half
 * @param prefix
 * @returns string
 */
export const getVintageHalfText = (half?: VintageHalf | null, prefix = "") =>
  half ? `${prefix ? ` ${prefix} ` : ""}${i18n.t(half)}` : "";
