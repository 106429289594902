import { FormikHelpers } from "formik";
import { useCallback, useContext } from "react";

import { ModalContext } from "@/app/components/modal-provider/modal-provider";
import { AssetType, RegistryCode, useExportCommoditiesMutation } from "@/app/types/generated/graphql";

import { ExportCommoditiesFormValues, UseCommoditiesExport } from "./commodities-export.types";

export const exportCommoditiesInitialFormState = {
  initialValues: {
    serialIndexFrom: 0,
    serialIndexTo: 0,
    serialPrefix: "",
    tradingAccount: { label: "", value: "" },
    registryCode: RegistryCode.Mrets,
    assetType: AssetType.Rec,
  },
};

export const exportRecsBySerial = async (
  exportCommodities: ReturnType<typeof useExportCommoditiesMutation>["0"],
  input: ExportCommoditiesFormValues,
  action: FormikHelpers<ExportCommoditiesFormValues>,
) => {
  const { tradingAccount, serialIndexFrom, serialIndexTo, ...rest } = input;
  await exportCommodities({
    variables: {
      exportCommoditiesBySerialInput: {
        serialIndexFrom: Number(serialIndexFrom),
        serialIndexTo: Number(serialIndexTo),
        tradingAccountUid: input.tradingAccount.value,
        ...rest,
      },
    },
  });
  action.setSubmitting(false);
  action.resetForm({
    values: exportCommoditiesInitialFormState.initialValues,
  });
  action.validateForm(exportCommoditiesInitialFormState.initialValues);
};

export const useCommoditiesExport: UseCommoditiesExport = () => {
  const [exportCommodities] = useExportCommoditiesMutation();

  const { showConfirmCommodityExportModal } = useContext(ModalContext);

  const confirmCommodityExport = useCallback(
    async (input, action) => {
      showConfirmCommodityExportModal({
        onSubmit: async () => await exportRecsBySerial(exportCommodities, input, action),
        data: {
          startRange: `${input.serialPrefix}-${input.serialIndexFrom}`,
          endRange: input.serialIndexTo,
          account: input.tradingAccount.label,
        },
      });
    },
    [showConfirmCommodityExportModal, exportCommodities],
  );

  return {
    confirmCommodityExport,
    initialFormState: exportCommoditiesInitialFormState,
  };
};
