import { Box, Button, Flex } from "@powerledger/ui-component-lib";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import MenuDotsSVG from "@/assets/icons/vectors/menu-dots.svg";

import { TableActionDropdownProps } from "./table-action-buttons.types";

export const TableActionDropdown: React.FC<TableActionDropdownProps> = ({
  isLastRow,
  buttonSx,
  containerSx,
  itemSx,
  actions,
  defaultOpen = false,
}) => {
  const [showMenu, setShowMenu] = useState(defaultOpen);
  const { t } = useTranslation();
  const ref = useRef<HTMLElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleOutsideClick = (e: MouseEvent) => {
    if (e.target && !ref.current?.contains(e.target as Node) && !buttonRef.current?.contains(e.target as Node)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleOutsideClick);
    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Button
        ref={buttonRef}
        type="button"
        aria-label={t("Open Action Menu")}
        sx={{
          p: 2,
          bg: "transparent",
          "&:enabled:hover": {
            bg: "primary.100",
          },
          ...(showMenu
            ? {
                "&:enabled:focus": { bg: "primary.100" },
              }
            : { "&:enabled:focus": { bg: "transparent" } }),
          svg: {
            fill: "text",
          },
          ...buttonSx,
        }}
        /** Button not focused when filtered something from input field and clicked the first time
         * This is just used here to keep the input focused and let the button open without unfocusing the input
         */
        onMouseDown={(e) => {
          e.preventDefault();
        }}
        onClick={(e) => {
          e.preventDefault();
          setShowMenu((state) => !state);
        }}
      >
        {/* FIX THIS -> Use named components for */}
        {/* @ts-ignore */}
        <MenuDotsSVG width={15} height={15} />
      </Button>
      {showMenu && (
        <Flex
          ref={ref}
          sx={{
            position: "absolute",
            zIndex: 1,
            right: "90%",
            top: isLastRow ? "-190px" : -5,
            background: "primary.100",
            p: 3,
            flexDirection: "column",
            gap: 3,
            mt: 2,
            borderRadius: "25px",
            width: "max-content",
            ...containerSx,
          }}
          /**
           * Same issue as with above button
           */
          onMouseDown={(e) => {
            e.preventDefault();
          }}
        >
          {actions.map((action, i) => (
            <Box key={action.key || i} sx={itemSx}>
              {action}
            </Box>
          ))}
        </Flex>
      )}
    </Box>
  );
};
