import { Button, Chip, Flex, Table, Tooltip } from "@powerledger/ui-component-lib";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, Row } from "react-table";

import { TableValueWrapper } from "@/app/components/table-value-wrapper";
import { AppDateFormats, formatDate } from "@/app/lib/format-date";
import { getTableTranslation } from "@/app/lib/get-translations-for-components";
import { volumeFormatter } from "@/app/lib/volume-formatter";
import { CommodityTransactionStatus, RetirementStatus } from "@/app/types/generated/graphql";

import { getRetirementType } from "../../common/helpers";
import { RetirementRequestsListTableData, RetirementRequestsListTableProps } from "../retirement-requests-list.types";

const chipVariant = {
  [RetirementStatus.Retired]: "chips.primary",
  [RetirementStatus.Failed]: "chips.error",
  [RetirementStatus.Rejected]: "chips.error",
  [RetirementStatus.Pending]: "chips.info",
  [RetirementStatus.Requested]: "chips.info",
  [CommodityTransactionStatus.Success]: "chips.primary",
} as const;

export const RetirementStatusChip = ({
  id,
  statusMessage,
  status,
}: {
  statusMessage?: string | null;
  id: string;
  status: RetirementStatus | CommodityTransactionStatus;
}) => {
  const { t } = useTranslation();

  return (
    <Flex>
      <Chip
        sx={{
          variant: chipVariant[status],
        }}
        label={t(status)}
      />
      {statusMessage && <Tooltip id={id} content={<>{t(statusMessage)}</>} />}
    </Flex>
  );
};

export default function RetirementRequestsListTable({
  data,
  loading,
  pageInfo,
  fetchData,
  onSeeDetails,
}: Readonly<RetirementRequestsListTableProps>) {
  const { t } = useTranslation();
  const columns: Column<RetirementRequestsListTableData>[] = useMemo(
    () => [
      {
        Header: t("Retirement Number"),
        disableSortBy: true,
        accessor: "retirementNumber",
        Cell: ({ value }: { value: string }) => {
          return <TableValueWrapper value={value} />;
        },
      },
      {
        Header: t("Requested At"),
        accessor: "requestedOn",
        Cell: ({ value }: { value: string }) => {
          return (
            <TableValueWrapper
              value={formatDate(value, { formatStyle: AppDateFormats.AbbreviatedMonthFormatWithTime })}
              containerSx={{ minWidth: 80 }}
            />
          );
        },
      },
      {
        Header: t("Quantity"),
        accessor: "totalVolume",
        Cell: ({ value }: { value: number }) => {
          return <TableValueWrapper value={volumeFormatter(value)} />;
        },
      },
      {
        Header: t("Retirement Type"),
        id: "type",
        Cell: ({ row }: { row: Row<RetirementRequestsListTableData> }) => {
          return (
            <TableValueWrapper
              value={getRetirementType(row.original.retirementType, row.original.retirementCategory)}
            />
          );
        },
      },
      {
        Header: t("Status"),
        accessor: "status",
        Cell: ({ row, value }: { row: Row<RetirementRequestsListTableData>; value: RetirementStatus }) => {
          return <RetirementStatusChip status={value} id={row.id} />;
        },
      },
      {
        Header: t("Action"),
        id: "action",
        Cell: ({ row }: { row: Row<RetirementRequestsListTableData> }) => {
          return (
            <Flex>
              <Button
                variant="pill.compactSecondary"
                sx={{ py: 1, mr: 2 }}
                aria-label={t("See details for retirement request {{id}}", { id: row.original.retirementNumber })}
                onClick={() => {
                  onSeeDetails(row.original.id);
                }}
              >
                {t("See Details")}
              </Button>
            </Flex>
          );
        },
      },
    ],
    [t, onSeeDetails],
  );

  return (
    <Table
      loading={loading}
      showScrollArrows
      manualPagination
      columns={columns}
      dataSource={data}
      fetchData={fetchData}
      pageInfo={pageInfo}
      translation={getTableTranslation(t)}
    />
  );
}
