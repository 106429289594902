import { useOktaAuth } from "@okta/okta-react";
import { Navigate } from "react-router";

import { Login } from "./login/login";

export default function Home() {
  const { authState } = useOktaAuth();

  if (!authState?.isAuthenticated) {
    return <Login />;
  }

  return <Navigate to="/accounts" />;
}
