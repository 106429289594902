import { useTablePaginationManager } from "@powerledger/ui-component-lib";
import { debounce, startCase } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

import notifications from "@/app/container/notifications";
import {
  AccountServiceSortOrderInput,
  AccountStatus,
  AccountType,
  usePaginatedAccountsQuery,
} from "@/app/types/generated/graphql";

import { AccountTableData, SearchKeys, SearchTerms, UseAccountsTableFn } from "./accounts-table.types";

export const accountStatusOptions = (Object.keys(AccountStatus) as Array<keyof typeof AccountStatus>).map((key) => ({
  label: startCase(key),
  value: AccountStatus[key],
}));

export const accountTypeOptions = [
  {
    label: "Any Account Type",
    value: "",
  },
  ...(Object.keys(AccountType) as Array<keyof typeof AccountType>).map((key) => ({
    label: startCase(key),
    value: AccountType[key],
  })),
];

export const useAccountsTable: UseAccountsTableFn = () => {
  const prevSearchState = useLocation().state?.searchKeys;

  const { t } = useTranslation();

  const { pageInfo, fetchData, offset, sort, resetPage, setTotalItems } = useTablePaginationManager<
    AccountTableData,
    AccountServiceSortOrderInput[]
  >({});

  const [searchTerms, setSearchTerms] = useState<SearchTerms>(
    prevSearchState || {
      companyName: "",
      status: AccountStatus.Active,
      accountType: "",
    },
  );

  const {
    data,
    loading: accountsLoading,
    previousData,
  } = usePaginatedAccountsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        where: {
          id: searchTerms.id,
          company: {
            businessName: searchTerms.companyName,
          },
          type: searchTerms.accountType || null,
          status: searchTerms.status,
        },
        offSetPaginationInfo: {
          offset,
          limit: pageInfo.pageSize,
        },
        sortOrderInputs: sort,
      },
    },
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      setTotalItems(data?.paginatedAccounts?.offsetInfo?.total ?? 0);
    },
    onError(error) {
      notifications.error({
        description: t(error?.message),
      });
    },
  });
  const onAccountsSearch = useMemo(
    () =>
      debounce((value: string, key: SearchKeys) => {
        setSearchTerms((searchTerm) => ({
          ...searchTerm,
          [key]: value || "",
        }));
        resetPage();
      }, 500),
    [resetPage],
  );

  useEffect(() => {
    return () => {
      onAccountsSearch.cancel();
    };
  });

  const loading = accountsLoading;

  const accountsQuery = useMemo(() => data ?? previousData, [data, previousData]);

  return {
    accountsQuery,
    loading,
    onAccountsSearch,
    searchTerms,
    pageInfo,
    fetchData,
  };
};
