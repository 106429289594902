import { ChangeEvent, FocusEvent, FocusEventHandler, useEffect, useRef, useState } from "react";

type UseDebouncedInputParams = {
  value: string;
  onChange?: {
    (e: ChangeEvent<any>): void;
    <T = string | ChangeEvent<any>>(field: T): T extends ChangeEvent<any>
      ? void
      : (e: string | ChangeEvent<any>) => void;
  };
  onBlur: FocusEventHandler<HTMLInputElement> | undefined;
  name: string;
  shouldDebounce?: boolean;
};

export const useDebouncedInput = (
  { value, onChange, name, onBlur, shouldDebounce }: UseDebouncedInputParams,
  ms = 300,
) => {
  const [localState, setLocalState] = useState(value);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    setLocalState(value);
  }, [value]);

  // Debounce the Field Change For ms
  const handleFieldChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!shouldDebounce) {
      onChange?.({
        target: {
          name,
          value: e.target.value,
          files: e.target.files,
        },
        currentTarget: {
          name,
          value: e.target.value,
          files: e.target.files,
        },
      });
    } else {
      setLocalState(e.target.value);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        onChange?.({
          target: {
            name: name,
            value: e.target.value,
            files: e.target.files,
          },
          currentTarget: {
            name,
            value: e.target.value,
            files: e.target.files,
          },
        });
      }, ms);
    }
  };

  // Clear the Timeout, Push the Change and perform passed OnBlur function
  const handleFieldBlur = async (e: FocusEvent<HTMLInputElement, Element>) => {
    onBlur && onBlur(e);
    if (!shouldDebounce) return;
    timeoutRef.current && clearTimeout(timeoutRef.current);
    onChange?.({
      target: {
        name,
        value: localState,
      },
    });
  };
  return {
    localState: shouldDebounce ? localState : value,
    handleFieldChange,
    handleFieldBlur,
  };
};
