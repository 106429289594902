import { Button, Editor, Flex, IconLoading, Modal } from "@powerledger/ui-component-lib";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useModalHelpers } from "@/app/hooks/use-modal-helpers";
import {
  RetirementByIdDocument,
  RetirementHistoryAdminDocument,
  useRejectRetirementRequestMutation,
} from "@/app/types/generated/graphql";

import { RejectRetirementRequestModalProps } from "./reject-retirement-request-modal.types";

export const isHtmlEmpty = (text: string) => {
  // Regular expression to match text between HTML tags
  const regex = />([^<>]+)</g;
  let match;
  let hasText = false;

  while ((match = regex.exec(text)) !== null) {
    if (match[1].trim().length > 0) {
      hasText = true;
      break;
    }
  }

  return hasText;
};

export default function RejectRetirementRequestModal({
  retirementNumber,
  retirementId,
  closeModal,
  visible,
  reasons,
}: Readonly<RejectRetirementRequestModalProps>) {
  const { t } = useTranslation();
  const [rejectReason, setRejectReason] = useState(reasons ?? "");
  const [rejectRetirementRequest] = useRejectRetirementRequestMutation({
    refetchQueries: [RetirementByIdDocument, RetirementHistoryAdminDocument],
  });

  const { onConfirm, submitting } = useModalHelpers({
    onSubmit: async () => {
      await rejectRetirementRequest({
        variables: {
          rejectRetirementRequestInput: {
            retirementId,
            rejectReason,
          },
        },
      });
    },
    closeModal,
    messages: {
      successMessage: t("Successfully Rejected Retirement {{retirementNumber}}", { retirementNumber }),
    },
  });
  return (
    <Modal
      title={t("Are you sure you want to reject retirement {{retirementNumber}}", { retirementNumber })}
      subtitle={t("Reject reasons are mandatory to reject the request.")}
      onCancel={closeModal}
      visible={visible}
    >
      <Editor theme="snow" value={rejectReason} onChange={setRejectReason} />
      <Flex
        sx={{
          mt: 3,
          justifyContent: "flex-end",
          gap: 2,
        }}
      >
        <Button variant="error" onClick={onConfirm} disabled={submitting || !isHtmlEmpty(rejectReason)}>
          {t("Reject")}
        </Button>
        {submitting && <IconLoading size="small" />}
      </Flex>
    </Modal>
  );
}
