import {
  BlockButton,
  Box,
  Button,
  Chip,
  EyeSVG,
  Flex,
  Grid,
  Heading,
  IconAccountVerified,
  IconError,
  IconLoading,
  IconWarning,
  Text,
  ThemeUIStyleObject,
} from "@powerledger/ui-component-lib";
import { startCase } from "lodash";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { SeparatorLayout } from "@/app/components";
import { AddressFields } from "@/app/components/address-fields";
import { DocumentsGrid } from "@/app/components/documents-grid/documents-grid";
import { ModalContext } from "@/app/components/modal-provider/modal-provider";
import { PageHeader } from "@/app/components/page-header";
import { RefreshIconButton } from "@/app/components/refresh-icon-button";
import { getCompanyContactName } from "@/app/lib/account-service-helpers";
import { TRACEX_COUNTRY_FLAGS } from "@/app/lib/country-flag-helpers";
import {
  ApprovalStatus,
  EntityType,
  FundingSourceStatus,
  PaymentMethodCode,
  RegistryLinkStatus,
} from "@/app/types/generated/graphql";
import IrecLogo from "@/assets/logos/irec-logo.svg";
import MretsLogo from "@/assets/logos/mrets-logo.svg";

import { PaymentMethodIconsMap, PaymentProviderIconsMap } from "../../../lib/payment-provider-icons-map";
import { AccountDetailsEntityDetails, AccountDetailsViewProps } from "./account-details.types";
import { approvalStatusMap, hasPersonalRegistries, paymentStatusMap, registryStatusMap } from "./use-account-details";

export const registryLogoMap: Record<string, any> = {
  MRETS: (
    <Box
      sx={{
        svg: {
          image: {
            filter: "brightness(0)",
          },
        },
      }}
    >
      <MretsLogo />
    </Box>
  ),
  IREC: <IrecLogo />,
};

export const AccountDetailsView: React.FC<AccountDetailsViewProps> = ({
  tenantDetails,
  controller,
  loading,
  beneficialOwners,
  goBack,
  params,
  linkedPaymentMethods,
  refetchDetails,
  registryData,
  documentsLoading,
  viewIdentityMetadataDetails,
  documents,
  allowVerification,
  linkExpired,
  accountInfo,
  refetchDocuments,
  company,
  decideOnApplication,
  allowRejection,
  allowResubmission,
  showRequestForEditButton,
}) => {
  const { t } = useTranslation();

  const renderAccountDetails = useMemo(
    () => (
      <>
        <Box
          sx={{
            borderRadius: 25,
            textAlign: "center",
            p: 2,
            fontSize: 3,
          }}
        >
          <Flex
            sx={{
              mt: 4,
              gap: 2,
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <BlockButton
              sx={{
                fontSize: "18px",
                color: "text",
                padding: "10px",
                textAlign: "center",
                background: "background",
                width: "150px",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  svg: {
                    fill: accountInfo.type ? "primary.500" : "warning.500",
                  },
                }}
              >
                {accountInfo.type ? <IconAccountVerified size={10} /> : <IconError color="yellow" size={10} />}
              </Box>
              <Box
                sx={{
                  bottom: 0,
                  position: "absolute",
                }}
              >
                {t("{{type}}", { type: startCase(accountInfo.type?.toLowerCase() ?? "Unset") })}
              </Box>
            </BlockButton>
            <BlockButton displayName={tenantDetails?.displayName || ""}>
              <Box
                sx={{ width: "50%", fontSize: 1 }}
                aria-label={`${tenantDetails?.localisation?.countryCode.toLowerCase() || ""} flag`}
              >
                {tenantDetails?.displayName
                  ? TRACEX_COUNTRY_FLAGS[
                      tenantDetails?.localisation?.countryCode
                        ? tenantDetails?.localisation?.countryCode.toLowerCase()
                        : "-"
                    ]
                  : t("No Region Selected")}
              </Box>
            </BlockButton>
            <BlockButton
              label={linkedPaymentMethods?.paymentMethod?.code}
              id={linkedPaymentMethods?.paymentMethod?.code}
              onClick={viewIdentityMetadataDetails}
              sx={{
                fontSize: 1,
                ...(linkedPaymentMethods?.paymentMethod?.paymentProvider && {
                  "&:hover": {
                    boxShadow: "0 0 10px var(--theme-ui-colors-text)",
                  },
                  "&:hover :first-of-type": {
                    opacity: 10,
                  },
                  "&:hover :not(:first-of-type)": {
                    opacity: 0.5,
                  },
                }),
              }}
            >
              <Flex
                sx={{
                  opacity: 0,
                  position: "absolute",
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                  fontSize: 1,
                  color: "textDark",
                  fontWeight: "bolder",
                  justifyContent: "center",
                  gap: 2,
                  alignItems: "center",
                }}
              >
                <EyeSVG width="30" fill="text" />
              </Flex>
              {linkedPaymentMethods?.paymentMethod?.paymentProvider ? (
                <>
                  <Chip
                    sx={{
                      position: "absolute",
                      fontSize: 0,
                      width: "120px",
                      height: "50px",
                      marginTop: "-150px",
                      span: {
                        whiteSpace: "normal",
                      },
                    }}
                    variant={
                      paymentStatusMap[linkedPaymentMethods?.fundingSource?.status ?? FundingSourceStatus.Verifying]
                    }
                    label={t(`Bank {{status}}`, {
                      status: startCase(linkedPaymentMethods?.fundingSource?.status?.toLowerCase() ?? "Not Added"),
                    })}
                  />
                  <Flex sx={{ mb: 1, alignItems: "center" }}>
                    {PaymentProviderIconsMap[linkedPaymentMethods?.paymentMethod?.paymentProvider?.name || ""]}
                  </Flex>
                  <Text sx={{ fontWeight: "bold", color: "textDark" }}>+</Text>
                  <Flex sx={{ mt: 1, alignItems: "center" }}>
                    {PaymentMethodIconsMap[linkedPaymentMethods?.paymentMethod?.code as PaymentMethodCode]}
                  </Flex>
                </>
              ) : (
                t("No Funding Source Linked")
              )}
            </BlockButton>
            {hasPersonalRegistries(accountInfo.type) && (
              <BlockButton
                label={registryData?.[0]?.code}
                id={registryData?.[0]?.code}
                sx={{
                  fontSize: 1,
                }}
              >
                {registryData?.[0]?.code ? (
                  <Box sx={{ width: "80%" }}>
                    <Chip
                      sx={{
                        position: "absolute",
                        fontSize: 0,
                        width: "120px",
                        height: "50px",
                        marginTop: "-85px",
                        span: {
                          whiteSpace: "normal",
                        },
                      }}
                      variant={registryStatusMap[registryData?.[0]?.status ?? RegistryLinkStatus.Pending]}
                      label={t(`Registry {{status}}`, {
                        status: startCase((registryData?.[0]?.status ?? RegistryLinkStatus.Pending)?.toLowerCase()),
                      })}
                    />
                    {registryData?.[0]?.code && registryLogoMap[registryData?.[0]?.code]}
                  </Box>
                ) : (
                  t("No Registry Linked")
                )}
              </BlockButton>
            )}
          </Flex>
        </Box>
        <Grid sx={{ mt: 2 }} gap={2} columns={[2, 2]}>
          <DisplayContainer
            showRequestForEditButton={showRequestForEditButton}
            entityDetails={{
              status: company?.approvalState?.status,
              id: company?.approvalState?.id,
              accountId: accountInfo.id,
              entity: EntityType.Company,
            }}
            header={t("Company - {{name}}", {
              name: company?.businessName || "N/A",
            })}
            data={{
              EIN: company?.employerIdentificationNumber || "-",
              "Entity Type": company?.businessType || "-",
              "Entity Classification": company?.industryClassification?.businessClassificationName || "-",
              Address: <AddressFields address={company?.physicalAddress} />,
            }}
          />

          <DisplayContainer
            showRequestForEditButton={showRequestForEditButton}
            entityDetails={{
              status: controller?.approvalState?.status,
              id: controller?.approvalState?.id,
              accountId: accountInfo.id,
              entity: EntityType.CompanyContact,
            }}
            header={t("Controller - {{name}}", {
              name: getCompanyContactName(controller),
            })}
            data={{
              "Job Title": controller?.jobTitle || "-",
              "Date of Birth": controller?.dateOfBirth || "-",
              ...(controller?.identityNumber
                ? { "Social Security Number": controller?.identityNumber || "-" }
                : {
                    Passport:
                      controller?.passportNumber || controller?.passportCountryCode
                        ? `${controller?.passportNumber || ""}, ${controller?.passportCountryCode || ""}`
                        : "-",
                  }),
              Address: <AddressFields address={controller?.physicalAddress} />,
            }}
          />
          {beneficialOwners?.map((bo) => (
            <DisplayContainer
              showRequestForEditButton={showRequestForEditButton}
              key={bo?.id}
              entityDetails={{
                status: bo?.approvalState?.status,
                id: bo?.approvalState?.id,
                entity: EntityType.CompanyContact,
              }}
              header={t("Beneficial Owner - {{name}}", {
                name: getCompanyContactName(bo),
              })}
              data={{
                "Job Title": bo?.jobTitle || "-",
                "Date of Birth": bo?.dateOfBirth || "-",
                ...(bo?.identityNumber
                  ? { "Social Security Number": bo?.identityNumber || "-" }
                  : {
                      Passport:
                        bo?.passportNumber || bo?.passportCountryCode
                          ? `${bo?.passportNumber || ""}, ${bo?.passportCountryCode || ""}`
                          : "-",
                    }),
                Address: <AddressFields address={bo?.physicalAddress} />,
              }}
            />
          ))}
        </Grid>
        <Box
          sx={{
            mt: 3,
          }}
        >
          <DocumentsGrid
            accountId={accountInfo.id ?? ""}
            documents={documents}
            showRequestForEditButton={showRequestForEditButton}
            hideView={linkExpired || documentsLoading}
            header={
              params.id &&
              !loading && (
                <Flex
                  sx={{
                    position: "relative",
                    justifyContent: "space-between",
                    mt: 2,
                    flexWrap: "wrap",
                    alignContent: "center",
                    width: "100%",
                  }}
                >
                  <Heading
                    sx={{
                      fontSize: 4,
                    }}
                  >
                    {t("Documents")}
                  </Heading>
                  {linkExpired && (
                    <Flex
                      sx={{
                        fontSize: 1,
                        position: "absolute",
                        width: "100%",
                        top: -3,
                        color: "warning",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <IconWarning color="warning" />
                      {t("Link Expired, Please Refetch")}
                    </Flex>
                  )}
                  <Button
                    sx={{
                      bg: "transparent",
                      color: "text",
                      position: "relative",
                      py: 0,
                      borderRadius: 0,
                      marginLeft: "auto",
                      borderBottom: "1px solid var(--theme-ui-colors-text)",
                      p: 2,
                      "&:enabled:hover, &:enabled:focus": {
                        bg: "transparent",
                        opacity: 0.8,
                      },
                    }}
                    onClick={refetchDocuments}
                  >
                    {documentsLoading ? (
                      <IconLoading size="small" />
                    ) : (
                      <Text
                        sx={{
                          bottom: 0,
                        }}
                      >
                        {t("Refetch Documents")}
                      </Text>
                    )}
                  </Button>
                </Flex>
              )
            }
          />
        </Box>
        <Flex
          sx={{
            mt: 2,
            gap: 1,
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="error"
            onClick={() => decideOnApplication("reject")}
            disabled={!allowRejection}
            aria-label={t("reject application")}
          >
            {t("Reject")}
          </Button>
          <Button
            variant="info"
            onClick={() => decideOnApplication("resubmit")}
            disabled={!allowResubmission}
            aria-label={t("resubmit application")}
          >
            {t("Resubmit")}
          </Button>
          <Button
            onClick={() => decideOnApplication("accept")}
            disabled={!allowVerification}
            aria-label={t("accept application")}
          >
            {t("Accept")}
          </Button>
        </Flex>
      </>
    ),
    [
      linkedPaymentMethods,
      accountInfo,
      decideOnApplication,
      tenantDetails,
      allowRejection,
      allowVerification,
      allowResubmission,
      company,
      registryData,
      t,
      beneficialOwners,
      controller,
      documentsLoading,
      loading,
      documents,
      linkExpired,
      params.id,
      refetchDocuments,
      viewIdentityMetadataDetails,
      showRequestForEditButton,
    ],
  );

  return (
    <>
      <PageHeader
        goBack={goBack}
        title="Account Details"
        {...(accountInfo?.company?.businessName ? { subtitle: `of ${accountInfo.company?.businessName}` } : {})}
        extraContent={
          <Flex
            sx={{
              alignItems: "center",
              position: "absolute",
              right: 0,
            }}
          >
            <RefreshIconButton fetchData={refetchDetails} loading={loading} />
          </Flex>
        }
      />

      {params.id ? (
        loading ? (
          <Box
            sx={{
              div: {
                position: "relative",
              },
            }}
          >
            <Flex
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconLoading />
            </Flex>
          </Box>
        ) : (
          renderAccountDetails
        )
      ) : (
        <Text>{t("No Account Found")}</Text>
      )}
    </>
  );
};

const DisplayContainer = ({
  sx,
  header,
  data,
  entityDetails,
  showRequestForEditButton,
}: {
  sx?: ThemeUIStyleObject;
  header: string;
  data: Record<string, any>;
  showRequestForEditButton: AccountDetailsViewProps["showRequestForEditButton"];
  entityDetails?: AccountDetailsEntityDetails;
}) => {
  const { t } = useTranslation();
  const { showAccountRequestEditEntityModal } = useContext(ModalContext);
  const mappedStatus = approvalStatusMap[entityDetails?.status ?? ApprovalStatus.NotRequested];
  return (
    <Box
      sx={{
        borderRadius: 25,
        textAlign: "center",
        background: "background",
        p: 2,
        flex: 1,
        position: "relative",
        ...sx,
      }}
    >
      <Box
        sx={{
          fontWeight: "bold",
          fontSize: 3,
          borderBottom: "1px solid var(--theme-ui-colors-background)",
          display: "block",
        }}
      >
        <Text
          sx={{
            fontWeight: "bolder",
            fontSize: 3,
          }}
        >
          {header}
        </Text>
      </Box>
      <SeparatorLayout
        showSeparator={false}
        contentStyle={{
          p: 3,
          textAlign: "left",
          flex: ["", 1],
          width: ["", "100%"],
        }}
        data={data}
      />
      <Box
        sx={{
          my: 3,
        }}
      >
        <Flex
          sx={{
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 2,
            position: "absolute",
            bottom: "10px",
            right: "10px",
          }}
        >
          <Chip
            variant={mappedStatus}
            label={t(`{{status}}`, {
              status: startCase(entityDetails?.status?.toLowerCase() ?? ApprovalStatus.NotRequested.toLowerCase()),
            })}
          />
          {showRequestForEditButton(entityDetails) && (
            <Button
              variant="error"
              sx={{
                p: 1,
                fontSize: 0,
              }}
              onClick={() => {
                if (entityDetails?.id && entityDetails?.accountId) {
                  showAccountRequestEditEntityModal({
                    id: entityDetails.id,
                    accountId: entityDetails.accountId,
                    entityType: entityDetails.entity,
                    title: t(`Request Edit ({{header}})`, { header }),
                  });
                }
              }}
              aria-label={t("request edit {{entity}}", {
                entity: startCase(entityDetails?.entity?.toLowerCase())?.toLowerCase(),
              })}
            >
              {t("Request Edit")}
            </Button>
          )}
        </Flex>
      </Box>
    </Box>
  );
};
