import { TFunction } from "i18next";
import { Flex } from "theme-ui";

import { TableValueWrapper } from "../components/table-value-wrapper";
import { RECOrderAttributeOptions } from "./format-rec-options";
import { getFuelSourceIcon } from "./get-fuel-source-icon";

export const getCommonRecTableColumns = (t: TFunction, recOptions?: RECOrderAttributeOptions) => [
  {
    Header: t("Location"),
    accessor: "location",
    Cell: ({ value }: { value: string }) => <TableValueWrapper title="Locations" value={value} />,
    disableSortBy: true,
  },
  {
    Header: t("Fuel Type"),
    accessor: "fuelSources",
    Cell: ({ value }: { value: string[] }) => {
      return (
        <Flex sx={{ color: "textDarker" }}>
          {!value || value.length < 1 ? (
            "-"
          ) : (
            <TableValueWrapper
              title={"Fuel Sources"}
              getIcon={getFuelSourceIcon}
              value={value}
              getToolTipText={(key: string) =>
                recOptions?.fuelSourceOptions?.filter((x) => x.value === key)[0]?.label || ""
              }
            />
          )}
        </Flex>
      );
    },
    disableSortBy: true,
  },
];
