import { Modal } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";

import { CreateOrEditTradingHalt } from "../create-or-edit-trading-halt";
import { EditTradingHaltProps } from "./edit-trading-halt-modal.types";

export default function EditTradingHaltModal({ visible, closeModal, data }: EditTradingHaltProps) {
  const { t } = useTranslation();
  return (
    <Modal visible={visible} title={t("Edit Trading Halt")} hideCloseButton>
      <CreateOrEditTradingHalt data={data} onCancel={closeModal} postSubmit={closeModal} />
    </Modal>
  );
}
