import "react-quill/dist/quill.snow.css";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useModalHelpers } from "@/app/hooks/use-modal-helpers";
import {
  AccountDetailsDocument,
  AccountDocumentsDocument,
  ApprovalStatus,
  EntityType,
  useAccountDetailsQuery,
  useAccountDocumentsQuery,
  useUpdateApprovalStateMutation,
} from "@/app/types/generated/graphql";

import { SubmitArgs, UseAccountRequestEditEntityModal } from "./account-request-edit-entity-modal.types";
import { getCurrentErrorMessage } from "./helpers";

export const changeEntityApprovalStatus = async (
  status: SubmitArgs[0],
  updateApprovalState: ReturnType<typeof useUpdateApprovalStateMutation>[0],
  entityType: EntityType,
  editReasons: string,
  id: string,
  accountId: string,
) => {
  if (status === ApprovalStatus.Rejected)
    await updateApprovalState({
      variables: {
        input: {
          entityType,
          errorMessage: editReasons,
          accountId,
          id,
          status,
        },
      },
    });
  else {
    await updateApprovalState({
      variables: {
        input: {
          entityType,
          errorMessage: null,
          accountId,
          id,
          status,
        },
      },
    });
  }
};

export const useAccountEntityRequestEditModal: UseAccountRequestEditEntityModal = ({
  visible,
  closeModal,
  id,
  accountId,
  entityType,
  loading,
  title,
}) => {
  const { t } = useTranslation();

  const [updateApprovalState] = useUpdateApprovalStateMutation({
    refetchQueries: [AccountDetailsDocument, AccountDocumentsDocument],
  });

  const { data, loading: accountDetailsLoading } = useAccountDetailsQuery({
    fetchPolicy: "cache-first",
    variables: {
      where: {
        id: accountId,
      },
    },
  });
  const { data: documents, loading: documentLoading } = useAccountDocumentsQuery({
    fetchPolicy: "cache-first",
    variables: {
      where: {
        id: accountId,
      },
    },
  });
  const [editReasons, setEditReasons] = useState("");

  const { onConfirm, submitting } = useModalHelpers<SubmitArgs>({
    onSubmit: async (status) => {
      await changeEntityApprovalStatus(status, updateApprovalState, entityType, editReasons, id, accountId);
    },
    loading,
    closeModal,
    messages: {
      successMessage: t("Successfully Changed Entity Status"),
    },
  });

  const currentErrorMessage = getCurrentErrorMessage(
    id,
    entityType,
    data?.paginatedAccounts?.accounts?.[0],
    documents?.paginatedAccounts?.accounts?.[0],
  );

  const queryLoading = documentLoading || accountDetailsLoading;

  return {
    visible,
    closeModal,
    queryLoading,
    submitting,
    onConfirm,
    editReasons,
    setEditReasons,
    currentErrorMessage,
    title,
  };
};
