import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import { Box } from "theme-ui";

import { GenericTooltip } from "../tooltips";

export const StatusInfo = ({ message }: { message: string }) => {
  const { t } = useTranslation();

  const shortMessage = message.substring(0, 100);

  const statusInfo = ReactHtmlParser(message);
  const shortStatusInfo = ReactHtmlParser(shortMessage);

  return (
    <Box>
      {shortStatusInfo}
      {message.length > shortMessage.length && (
        <>
          <GenericTooltip delayHide={500} id={"status"} content={<>{statusInfo}</>} />
          <Box
            color="primary.500"
            data-tip={statusInfo}
            data-for={"status"}
            sx={{
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            {t("Show More")}
          </Box>
        </>
      )}
    </Box>
  );
};
