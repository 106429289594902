import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { ModalContext } from "@/app/components/modal-provider/modal-provider";
import notifications from "@/app/container/notifications";
import { getCompanyContactName, hasLinkedFundingSourceAndRegistries } from "@/app/lib/account-service-helpers";
import {
  Account,
  AccountDetailsDocument,
  AccountDetailsQuery,
  AccountDocumentsDocument,
  AccountDocumentsQuery,
  AccountStatus,
  AccountType,
  ApprovalStatus,
  Company,
  CompanyContact,
  ContactType,
  Document,
  FundingSourceStatus,
  LinkedPaymentMethod,
  RegistryLinkStatus,
  Tenant,
  useAccountDetailsQuery,
  useAccountDocumentsQuery,
  useRegistriesQuery,
  useRejectApplicationMutation,
  useResubmitByAdminMutation,
  useTenantsQuery,
  useVerifyAccountMutation,
} from "@/app/types/generated/graphql";

import { AccountDetailsViewProps, UseAccountDetailsFn } from "./account-details.types";

export const approvalStatusMap: Record<ApprovalStatus, "primary" | "error" | "info" | undefined> = {
  [ApprovalStatus.Approved]: "primary",
  [ApprovalStatus.Deactivated]: "error",
  [ApprovalStatus.Error]: "error",
  [ApprovalStatus.NotRequested]: undefined,
  [ApprovalStatus.Pending]: "info",
  [ApprovalStatus.Rejected]: "error",
  [ApprovalStatus.Suspended]: "error",
};

export const paymentStatusMap: Record<FundingSourceStatus, "primary" | "error" | "info"> = {
  [FundingSourceStatus.Added]: "info",
  [FundingSourceStatus.Error]: "error",
  [FundingSourceStatus.Removed]: "error",
  [FundingSourceStatus.Verified]: "primary",
  [FundingSourceStatus.Verifying]: "info",
  [FundingSourceStatus.WaitingForCustomerAction]: "info",
};

export const registryStatusMap: Record<RegistryLinkStatus, "primary" | "error" | "info"> = {
  [RegistryLinkStatus.Registered]: "primary",
  [RegistryLinkStatus.RegistrationFailed]: "error",
  [RegistryLinkStatus.Pending]: "info",
};

/** TEN MINUTES */
export const SIGNED_URL_EXPIRY_TIME = 600000;

export const hasPersonalRegistries = (type?: AccountType | null) => !!(type && [AccountType.Registry].includes(type));

export const refineDocumentsForView = ({
  documents,
  account,
}: {
  documents?: AccountDocumentsQuery;
  account?: NonNullable<AccountDetailsQuery["paginatedAccounts"]["accounts"]>["0"];
}) => {
  if (!documents || !account) return [];
  const companyName = account?.company?.businessName;
  const accountHolder = documents?.paginatedAccounts?.accounts?.[0];
  const allDocuments: Array<Document & Record<"holder", { type: string; name: string }>> = [];
  if (accountHolder?.company) {
    for (const doc of accountHolder?.company?.documents || []) {
      if (doc) {
        allDocuments.push({
          ...doc,
          holder: {
            type: "COMPANY",
            name: companyName || "N/A",
          },
        });
      }
    }
  }
  for (const contact of accountHolder?.company?.contacts || []) {
    if (contact) {
      for (const doc of contact.documents || []) {
        if (doc) {
          const contactDetail = account?.company?.contacts?.find((contactDetail) => contactDetail.id === contact.id);

          allDocuments.push({
            ...doc,
            holder: { type: contact.type as string, name: getCompanyContactName(contactDetail) },
          });
        }
      }
    }
  }

  return allDocuments;
};

export const decideApplicationState = async (
  decision: "reject" | "accept" | "resubmit",
  rejectAccount: ReturnType<typeof useRejectApplicationMutation>[0],
  acceptAccount: ReturnType<typeof useVerifyAccountMutation>[0],
  resubmitApplication: ReturnType<typeof useResubmitByAdminMutation>[0],
  accountId: string,
) => {
  if (decision === "accept") {
    return acceptAccount({
      variables: {
        accountId,
      },
    });
  } else if (decision === "reject")
    return rejectAccount({
      variables: {
        input: {
          accountId,
        },
      },
    });

  return resubmitApplication({
    variables: {
      input: {
        accountId,
      },
    },
  });
};

export const useAccountDetails: UseAccountDetailsFn = () => {
  const params = useParams();
  const { t } = useTranslation();

  const { data: tenantsData, loading: tenantsLoading } = useTenantsQuery({
    onError(error) {
      notifications.error({
        description: t(error?.message),
      });
    },
  });
  const [linkExpired, setLinkExpired] = useState(false);
  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const location = useLocation();

  const { showConfirmAcceptRejectResubmitApplicationModal, showIdentityMetaDataModal } = useContext(ModalContext);

  const [verifyAccount] = useVerifyAccountMutation({
    refetchQueries: [AccountDetailsDocument, AccountDocumentsDocument],
  });

  const [rejectAccount] = useRejectApplicationMutation({
    refetchQueries: [AccountDetailsDocument, AccountDocumentsDocument],
  });

  const [resubmitApplication] = useResubmitByAdminMutation({
    refetchQueries: [AccountDetailsDocument, AccountDocumentsDocument],
  });

  const {
    data: accountData,
    loading: accountsLoading,
    refetch,
  } = useAccountDetailsQuery({
    skip: !params.id,
    variables: {
      where: {
        id: params.id,
      },
    },
    notifyOnNetworkStatusChange: true,
    onError(error) {
      notifications.error({
        description: t(error?.message),
      });
    },
  });

  const {
    data: documents,
    loading: documentsLoading,
    refetch: refetchAccountDocuments,
  } = useAccountDocumentsQuery({
    /** document is a subgraph of accounts so to prevent a cache update and refresh when we fetch this, fetch-policy is set to no-cache */
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: {
      where: {
        id: params.id,
      },
    },
    onError(error) {
      notifications.error({
        description: t(error?.message),
      });
    },
  });

  useEffect(() => {
    setLinkExpired(false);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    /** The documents signedURL will get expired every 10 minutes */
    timerRef.current = setTimeout(() => {
      setLinkExpired(true);
    }, SIGNED_URL_EXPIRY_TIME);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [documents]);

  const account = accountData?.paginatedAccounts?.accounts?.[0];

  const { data: registries, loading: loadingRegistries } = useRegistriesQuery({
    skip: !account?.linkedRegistries?.length,
    variables: { where: { idIn: [account?.linkedRegistries?.[0]?.id] } },
    onError(error) {
      notifications.error({
        description: t(error?.message),
      });
    },
  });

  const tenants = (tenantsData?.tenants || []) as Tenant[];

  const tenantDetails = tenants?.find((tenant) => tenant.id === account?.tenantId);

  const controller = account?.company?.contacts?.find(
    (contact) => contact?.type === ContactType.Controller,
  ) as CompanyContact;

  const loading = tenantsLoading || accountsLoading || loadingRegistries;

  const beneficialOwners = (account?.company?.contacts?.filter(
    (contact) => contact?.type === ContactType.BeneficialOwner,
  ) || []) as CompanyContact[];

  const navigate = useNavigate();

  const goBack = useCallback(() => {
    navigate(`/accounts`, {
      state: {
        searchKeys: location.state?.searchKeys || undefined,
      },
    });
  }, [navigate, location.state]);

  const refetchDocuments = useCallback(async () => {
    await refetchAccountDocuments();
  }, [refetchAccountDocuments]);

  const allDocuments = useMemo(() => refineDocumentsForView({ documents, account }), [documents, account]);

  const viewIdentityMetadataDetails = useCallback(() => {
    showIdentityMetaDataModal({ userId: account?.id });
  }, [showIdentityMetaDataModal, account?.id]);

  const decideOnApplication = useCallback(
    async (state: "reject" | "accept" | "resubmit" = "accept") => {
      showConfirmAcceptRejectResubmitApplicationModal({
        account: `${account?.company?.businessName} - ${account?.email}`,
        modalType: state,
        onConfirm: async () => {
          await decideApplicationState(state, rejectAccount, verifyAccount, resubmitApplication, account?.id ?? "");
        },
      });
    },
    [rejectAccount, verifyAccount, resubmitApplication, showConfirmAcceptRejectResubmitApplicationModal, account],
  );

  const allowVerification =
    hasLinkedFundingSourceAndRegistries(account as Account, !hasPersonalRegistries(account?.type)) &&
    account?.company?.approvalState?.status === ApprovalStatus.Approved &&
    ![AccountStatus.Active, AccountStatus.Onboarding, AccountStatus.RetryReviewing].includes(account?.status);

  const allowRejection = account?.status === AccountStatus.RetryReviewing;

  const allowResubmission = account?.status === AccountStatus.RetryReviewing;

  const linkedPaymentMethods = account?.linkedPaymentMethods?.[0] as LinkedPaymentMethod;

  const company = account?.company as Company;

  const showRequestForEditButton: AccountDetailsViewProps["showRequestForEditButton"] = useCallback(
    (entityDetails) => {
      if (account?.status === AccountStatus.RetryReviewing && entityDetails?.accountId && entityDetails?.id) {
        return true;
      }
      return false;
    },
    [account?.status],
  );

  const registryData =
    registries?.registries?.map((reg) => ({
      ...reg,
      status:
        account?.linkedRegistries?.find((linkedReg) => linkedReg.id === reg.id)?.status ?? RegistryLinkStatus.Pending,
    })) ?? [];

  const accountInfo = useMemo(
    () => ({
      id: account?.id,
      type: account?.type,
      company: {
        id: account?.company?.id,
        businessName: account?.company?.businessName,
      },
    }),
    [account?.id, account?.type, account?.company?.id, account?.company?.businessName],
  );

  const refetchDetails = useCallback(() => {
    refetch();
  }, [refetch]);

  return {
    tenantDetails,
    controller,
    loading,
    linkedPaymentMethods,
    company,
    beneficialOwners,
    goBack,
    params,
    refetchDetails,
    refetchDocuments,
    registryData,
    documents: allDocuments,
    documentsLoading,
    linkExpired,
    decideOnApplication,
    allowVerification,
    allowRejection,
    viewIdentityMetadataDetails,
    allowResubmission,
    accountInfo,
    showRequestForEditButton,
  };
};
