import { LegacySelect, SearchSVG } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";
import { Box, Flex } from "theme-ui";

import { Input } from "@/app/components";
import { RefreshIconButton } from "@/app/components/refresh-icon-button";
import { RequestInfoMessage } from "@/app/components/retirement-export-requests-common";
import { getSelectTranslation } from "@/app/lib/get-translations-for-components";

import { ExportRequestsListViewProps } from "./export-request-list.types";
import ExportRequestsListTable from "./export-request-table/export-requests-table";
import { exportStatusOptions } from "./use-export-request-list";

export const ExportRequestsListView: React.FC<ExportRequestsListViewProps> = ({
  handleFilterChange,
  filterValues,
  loading,
  data,
  fetchData,
  pageInfo,
  onSeeDetails,
  refreshData,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Flex
        sx={{
          py: 3,
          mb: 3,
          gap: 2,
          flexWrap: "wrap",
          flexDirection: ["column", "row"],
          justifyContent: "flex-end",
        }}
      >
        <Box aria-label="search export number">
          <Input
            debounce
            name="search export number"
            sx={{
              width: 280,
              color: "textDarker",
              border: "1px solid var(--theme-ui-colors-shadow)",
              bg: "transparent",
              height: 44,
            }}
            prefix={{
              outside: false,
              component: (
                <Flex sx={{ alignItems: "center", svg: { path: { fill: "text" } } }}>
                  <SearchSVG width={20} />
                </Flex>
              ),
            }}
            defaultValue={filterValues?.exportNumber}
            onChange={(e) => {
              handleFilterChange("exportNumber", (e.currentTarget as HTMLInputElement).value);
            }}
            placeholder={t("Enter Export Number")}
          />
        </Box>
        <Box aria-label="filter export requests status" sx={{ width: "20%" }}>
          <LegacySelect
            sx={{
              [`div[class*="container"]>div`]: {
                bg: "background",
              },
              [`div[class*="menu"]>div`]: {
                bg: "background",
              },
            }}
            value={exportStatusOptions.find((option) => option.value === filterValues.status)}
            options={exportStatusOptions}
            translation={getSelectTranslation(t)}
            onChange={(option) => {
              if (option) {
                handleFilterChange("status", option.value);
              } else handleFilterChange("status", null);
            }}
            placeholder="Select Export Status"
            isClearable
          />
        </Box>
        <RefreshIconButton loading={loading} fetchData={refreshData} />
      </Flex>
      <RequestInfoMessage />
      <ExportRequestsListTable
        loading={loading}
        fetchData={fetchData}
        pageInfo={pageInfo}
        data={data}
        onSeeDetails={onSeeDetails}
      />
    </>
  );
};
