import { CRSSmallSVG, Flex, ThemeUIStyleObject } from "@powerledger/ui-component-lib";

import { CERTIFICATIONS, CRS, ELIGIBILITIES } from "@/app/lib/format-attributes";
import { OrderPosition } from "@/app/types/generated/graphql";

import { TableValueWrapper } from "../table-value-wrapper";

export const certificationIconMap = (certification = "") => {
  if (certification.includes(CRS)) {
    return (
      <Flex sx={{ padding: 1, alignItems: "center" }}>
        <CRSSmallSVG />
      </Flex>
    );
  }
  return null;
};

export const EligibilityValueFormatter = ({
  certificationsAndEligibilities = [],
  position,
  sx,
}: {
  position: OrderPosition;
  sx?: ThemeUIStyleObject;
  certificationsAndEligibilities: { label: string; value: string[] }[];
}) => {
  const certifications =
    certificationsAndEligibilities.find((certEligibility) => certEligibility.label === CERTIFICATIONS)?.value ?? [];
  const eligibilities =
    certificationsAndEligibilities.find((certEligibility) => certEligibility.label === ELIGIBILITIES)?.value ?? [];

  const eligibilityToShow = [...certifications, ...eligibilities];

  const emptyEligibility = position === OrderPosition.Ask ? "-" : "Any";

  return (
    <Flex
      sx={{
        alignItems: "center",
      }}
    >
      {certificationIconMap(eligibilityToShow[0])}
      <TableValueWrapper
        value={eligibilityToShow.filter(Boolean).length ? eligibilityToShow : emptyEligibility}
        title="Eligibilities"
        containerSx={{
          minWidth: 100,
          ...sx,
        }}
      />
    </Flex>
  );
};
