import { ExportToCsv, Options } from "export-to-csv";
import { saveAs } from "file-saver";

//Default Options For CSV Download
const defaultOptions: Options = {
  fieldSeparator: ",",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
};

export const downloadCSV = (filename: string, data: any, options: Options = {}) => {
  const csvExporter = new ExportToCsv({
    ...defaultOptions,
    ...options,
  });
  const csvData = csvExporter.generateCsv(data, true);
  const csvBlob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
  return saveAs(csvBlob, filename);
};
