export const blobToDataURI = async (blob: Blob) => {
  try {
    const event = await new Promise((r: (value: ProgressEvent<FileReader>) => void) => {
      const a = new FileReader();
      a.onload = r;
      a.readAsDataURL(blob);
    });
    let result = event.target?.result ?? "";
    if (typeof result !== "string") {
      result = arrayBufferToBase64(result);
    }
    return result;
  } catch (e) {
    throw new Error();
  }
};

export const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};
