import { Box, Flex, useTheme } from "@powerledger/ui-component-lib";
import Cleave from "cleave.js/react";
import { getIn } from "formik";

import { useDebouncedInput } from "../../hooks/use-debounced-input";
import { FieldSelectors } from "../../hooks/use-formik-input-error";
import { CleaveInputProps } from "./cleave-input.types";
import { getCleaveOptions } from "./get-cleave-options";

export const CleaveInput: React.FC<CleaveInputProps> = ({
  prefix,
  field,
  cleaveOptions = { numeralThousandsGroupStyle: "thousand" },
  form,
  sx,
  suffix,
  type,
  disabled,
  onChange,
  onKeyDown,
  value,
}) => {
  const { theme } = useTheme();
  const errorStyle = getIn(form.touched, field.name) && getIn(form.errors, field.name) && FieldSelectors["input"];
  const { input = {}, ...restSx } = (sx || {}) as any;

  const { handleFieldChange, localState, handleFieldBlur } = useDebouncedInput({
    onChange: onChange || field.onChange,
    value: value || field.value,
    name: field.name,
    onBlur: field.onBlur,
    shouldDebounce: true,
  });

  return (
    <Flex
      sx={{
        width: "100%",
        alignItems: "center",
        position: "relative",
        input: {
          ...theme.forms?.["input"],
          ...input,
          ...errorStyle,
          ...(prefix ? { pl: 4, pr: 3 } : { px: 3 }),
        },
        ...restSx,
      }}
    >
      {prefix && (
        <Box
          sx={{
            position: "absolute",
            left: 3,
            color: "textDarker",
            fontWeight: "light",
            ...errorStyle,
            border: 0,
            fontSize: input?.fontSize || 3,
          }}
        >
          {prefix}
        </Box>
      )}
      <Cleave
        options={getCleaveOptions(type, cleaveOptions)}
        name={field.name}
        onChange={(event) => {
          handleFieldChange({
            ...event,
            target: {
              ...event.target,
              value: event.target.rawValue,
            },
          });
        }}
        value={localState}
        onBlur={handleFieldBlur}
        disabled={disabled}
        onKeyDown={(e) => {
          /** If onKeyDown is not passed, prohibit Enter as it will be defaulted to other event, eg. closing the modal */
          if (onKeyDown) {
            onKeyDown(e);
          } else {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }
        }}
      />
      {suffix && (
        <Box
          sx={{
            position: "absolute",
            right: 3,
            color: "textDarker",
            fontWeight: "light",
            ...errorStyle,
            border: 0,
            fontSize: input?.fontSize || 3,
          }}
        >
          {suffix}
        </Box>
      )}
    </Flex>
  );
};
