import { Box } from "theme-ui";

import { Address } from "@/app/types/generated/graphql";

export const AddressFields = ({ address }: { address?: Address | null }) => (
  <Box
    sx={{
      textAlign: "left",
    }}
  >
    <Box>{address?.line1 || address?.state ? `${address?.line1 || ""}, ${address?.state || ""}` : "-"}</Box>
    <Box>{address?.city || address?.postalCode ? `${address?.city || ""}, ${address?.postalCode || ""}` : "-"}</Box>
    <Box>{`${address?.countryCode || "-"}`}</Box>
  </Box>
);
