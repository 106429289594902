export enum SupportEmailTypes {
  SUPPORT_EMAIL_INTERNAL_KEY = "internal",
  SUPPORT_EMAIL_USA_KEY = "USA",
}

export enum SupportEmails {
  SUPPORT_EMAIL_INTERNAL = "tracexadministrator@powerledger.io",
  SUPPORT_EMAIL_USA = "tracexussupport@powerledger.io",
}

const emailMap: { [key: string]: SupportEmails } = {
  internal: SupportEmails.SUPPORT_EMAIL_INTERNAL,
  USA: SupportEmails.SUPPORT_EMAIL_USA,
};
export const getSupportEmail = (type: SupportEmailTypes = SupportEmailTypes.SUPPORT_EMAIL_USA_KEY) => {
  return emailMap[type] || SupportEmails.SUPPORT_EMAIL_USA;
};
