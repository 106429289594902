import { FormikHelpers } from "formik";

import { ExportCommodity } from "@/app/pages/managed-commodities/managed-commodities.types";

export enum STEPS {
  EXPORT_FORM,
  EXPORT_SUBMIT,
  EXPORT_SUCCESS,
}

export type ManagedCommoditiesExportModalCustomProps = {
  exportData: ExportCommodity;
  onNavigate: () => void;
};

export type ManagedCommoditiesExportModalProps = ManagedCommoditiesExportModalCustomProps & {
  visible: boolean;
  closeModal: () => void;
  onExportSubmit?: (values: FormValues, action: FormikHelpers<FormValues>) => void;
  currentStep?: STEPS;
  modalTitle?: string;
  handleStepChange?: (steps: STEPS) => void;
};

export type FormValues = {
  exportVolume: number;
};

export type ExportQuanityProps = {
  currentStep?: STEPS;
};
