import { Button, Chip, LegacyCheckbox, Table, Tooltip } from "@powerledger/ui-component-lib";
import { format } from "date-fns";
import { startCase } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, Row } from "react-table";
import { Box, Flex } from "theme-ui";

import { AccountsSelect, AttributesTooltip, Input, LocalDatePicker } from "@/app/components";
import { EligibilityValueFormatter } from "@/app/components/eligibility-value-formatter";
import { PageHeader } from "@/app/components/page-header";
import { RefreshIconButton } from "@/app/components/refresh-icon-button";
import { TableValueWrapper } from "@/app/components/table-value-wrapper";
import { getCommonRecTableColumns } from "@/app/lib/common-rec-table-columns";
import { CertificationAndEliglibilityType } from "@/app/lib/format-attributes";
import { AppDateFormats, formatDate } from "@/app/lib/format-date";
import { getTableTranslation } from "@/app/lib/get-translations-for-components";
import { AccountStatus, AccountType, OrderPosition, RecAssetAttributes } from "@/app/types/generated/graphql";

import { ManagedCommoditiesData, ManagedCommoditiesViewProps } from "./managed-commodities.types";

export const ManagedCommoditiesView: React.FC<ManagedCommoditiesViewProps> = ({
  recOptions,
  filterValues,
  handleFilterChange,
  loading,
  refetchData,
  goBack,
  tableData,
  companyName,
  pageInfo,
  fetchData,
  updateSelectedAccountDetails,
  selectedAccountDetails,
  hasParamsId,
  accountQuery,
  onExportCommodity,
  showAllAttributes,
  setShowAllAttributes,
}) => {
  const { t } = useTranslation();
  const tableColumns: Column<ManagedCommoditiesData>[] = useMemo(
    () => [
      {
        Header: t("Company Name"),
        accessor: (row) => row.account.company?.businessName,
        id: "businessName",
        Cell: ({ value }: { value: string }) => {
          return <TableValueWrapper value={value ?? "-"} />;
        },
        disableSortBy: true,
      },
      {
        Header: t("Vintage"),
        accessor: "generatedOn",
        Cell: ({ value }: { value: string }) => (
          <TableValueWrapper
            value={formatDate(value, { formatStyle: AppDateFormats.UniversalDateFormat, noTZ: true, noOffset: true })}
          />
        ),
      },
      {
        Header: t("Eligibility"),
        accessor: "certificationsAndEligibilities",
        Cell: ({ value }: { value: CertificationAndEliglibilityType[] }) => {
          return <EligibilityValueFormatter certificationsAndEligibilities={value} position={OrderPosition.Ask} />;
        },
        disableSortBy: true,
      },
      ...(getCommonRecTableColumns(t, recOptions) as Column<ManagedCommoditiesData>[]),
      {
        Header: t("Commencement Date (COD)"),
        id: "commencementDate",
        Cell: ({ row }: { row: Row<ManagedCommoditiesData> }) => {
          const commencementDate = (row.original.attributes as RecAssetAttributes)?.commencementDate;
          const displayValue = commencementDate ? format(new Date(commencementDate), "P") : "N/A";
          return <TableValueWrapper value={displayValue} />;
        },
        disableSortBy: true,
      },
      {
        Header: t("Generator Name"),
        accessor: "generatorName",
        Cell: ({ value }: { value: string }) => <TableValueWrapper value={value} />,
        disableSortBy: true,
      },
      {
        Header: t("Generator Code"),
        accessor: "generatorDesc",
        Cell: ({ value }: { value: string }) => <TableValueWrapper value={value} />,
        disableSortBy: true,
      },
      {
        Header: t("Serial Range"),
        id: "serial",
        disableSortBy: true,
        Cell: ({ row }: { row: Row<ManagedCommoditiesData> }) => {
          const serial = `${row.original.serialPrefix}-${row.original.serialFrom} to ${row.original.serialTo}`;
          return <TableValueWrapper value={serial} />;
        },
      },
      {
        Header: t("Available Volume"),
        accessor: "availableVolume",
        Cell: ({ value }: { value: number }) => (
          <TableValueWrapper value={value ? new Intl.NumberFormat().format(value) : "-"} />
        ),
      },
      {
        Header: t("Action"),
        id: "action",
        Cell: ({ row }: { row: Row<ManagedCommoditiesData> }) => {
          const serial = `${row.original.serialPrefix}-${row.original.serialFrom} to ${row.original.serialTo}`;
          const { vintage, availableVolume, id, account, serialFrom, serialTo, serialPrefix } = row.original;
          return (
            <Flex>
              {row.original.account.type === AccountType.Registry ? (
                <Button
                  variant="pill.compactSecondary"
                  aria-label={t("Export commodity of serial {{id}}", {
                    id: row.original.serialPrefix,
                  })}
                  onClick={() => {
                    onExportCommodity({
                      vintage,
                      serial,
                      availableVolume,
                      id,
                      serialFrom,
                      serialTo,
                      serialPrefix,
                      account,
                    });
                  }}
                >
                  {t("Export Commodity")}
                </Button>
              ) : (
                <Tooltip id={serial} content={<Box>{t("Non Registry Commodity")}</Box>} />
              )}
            </Flex>
          );
        },
      },
    ],
    [t, recOptions, onExportCommodity],
  );
  return (
    <>
      {hasParamsId && (
        <PageHeader
          title="Managed Commodities"
          goBack={goBack}
          {...(companyName ? { subtitle: `For ${companyName}` } : {})}
        />
      )}
      <Flex
        sx={{
          pb: 3,
          pt: 4,
          gap: 3,
        }}
      >
        <Box
          sx={{
            flex: hasParamsId ? 2 : 1,
          }}
        >
          <Input
            debounce
            onChange={(event) => handleFilterChange("serialPrefix", (event.target as HTMLInputElement).value)}
            placeholder="Enter serial prefix to search..."
            defaultValue={filterValues?.serialPrefix}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
          }}
        >
          <LocalDatePicker
            isClearable
            showMonthYearPicker
            dateFormat={AppDateFormats.UniversalYearMonthFormat}
            noOffset
            shouldCloseOnSelect={false}
            placeholder="Select vintage to filter"
            onChange={(date) => {
              handleFilterChange("vintage", date);
            }}
            value={filterValues.vintage ?? ""}
          />
        </Box>
        {!hasParamsId && (
          <Box
            sx={{
              flex: 1,
            }}
          >
            {/* if selectedAccountDetails has no value then making selectedValue null to show placeholder */}
            <AccountsSelect
              selectedValue={
                Object.values(selectedAccountDetails || {}).length
                  ? {
                      label: selectedAccountDetails?.label ?? "",
                      value: selectedAccountDetails?.value,
                    }
                  : null
              }
              filter={{
                status: AccountStatus.Active,
              }}
              disabled={false}
              onFieldChange={(option) => {
                handleFilterChange("accountId", option?.value);
                updateSelectedAccountDetails(option);
              }}
            />
          </Box>
        )}
        <Flex
          sx={{
            alignItems: "center",
          }}
        >
          <RefreshIconButton fetchData={refetchData} loading={loading} />
        </Flex>
      </Flex>
      <Flex
        sx={{
          flexDirection: "column",
          gap: 2,
        }}
      >
        <LegacyCheckbox
          checked={showAllAttributes}
          sx={{
            color: "textDarker",
          }}
          label={t("Show All Attributes")}
          onChange={() => setShowAllAttributes((prev) => !prev)}
        />
        {!!accountQuery?.accounts.length && (
          <Flex>
            <Chip label={startCase(accountQuery.accounts[0]?.type ?? "")} variant="info" />
          </Flex>
        )}
      </Flex>

      <Box sx={{ mt: 3 }}>
        <Table
          columns={tableColumns}
          dataSource={tableData}
          pageInfo={pageInfo}
          fetchData={fetchData}
          manualPagination
          loading={loading}
          showScrollArrows
          translation={getTableTranslation(t)}
          initialState={
            showAllAttributes
              ? {}
              : {
                  hiddenColumns: ["location", "generatorName", "generatorDesc"],
                }
          }
        />
      </Box>
      <AttributesTooltip />
    </>
  );
};
