import {
  Account,
  ApprovalStatus,
  CompanyContact,
  Document,
  DocumentStatus,
  PaymentMethodLinkStatus,
  RegistryLinkStatus,
} from "../types/generated/graphql";

/**
 * Checks whether all documents are in uploaded state
 * @param documents
 * @returns
 */
export const areDocumentsUploaded = (documents?: Document[] | null) => {
  return documents?.every((document) => document.status === DocumentStatus.Uploaded);
};

/**
 * Checks if some documents or payment methods are missing in account
 * @param account
 * @returns
 */
export const hasMissingDetails = (account: Account) =>
  !account?.linkedPaymentMethods?.length ||
  !account?.linkedPaymentMethods?.some((paymentMethod) => paymentMethod.status === PaymentMethodLinkStatus.Active) ||
  !account?.linkedRegistries?.some((registry) => registry?.status === RegistryLinkStatus.Registered) ||
  !areDocumentsUploaded(account?.company?.documents as Document[]) ||
  !account?.company?.contacts?.every((company) => areDocumentsUploaded(company?.documents as Document[]));

/**
 * Check approved status of account and its nested entities => company and its contacts
 * @param account
 * @returns
 */
export const checkNestedApprovedStatus = (account?: Account | null) => {
  return !!(
    account &&
    account?.company?.approvalState?.status === ApprovalStatus.Approved &&
    account?.company?.contacts?.every((contact) => contact.approvalState?.status === ApprovalStatus.Approved)
  );
};

export const getCompanyContactName = (contact?: CompanyContact, defaultName = "N/A") =>
  contact?.firstName || contact?.lastName
    ? `${contact?.firstName || ""} ${contact?.lastName || ""}`.trim()
    : defaultName;

export const hasLinkedFundingSourceAndRegistries = (account?: Account, skipRegistryCheck = false) =>
  !!account?.linkedPaymentMethods?.some((paymentMethod) => paymentMethod.status === PaymentMethodLinkStatus.Active) &&
  !!(
    skipRegistryCheck ||
    account?.linkedRegistries?.some((registry) => registry?.status === RegistryLinkStatus.Registered)
  );
