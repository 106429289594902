import { IconProps } from "@powerledger/ui-component-lib";
import { FC } from "react";

export const UnsetIconWrapperStyle: FC<{
  Icon: FC<IconProps>;
}> = ({ Icon }) => {
  return (
    <Icon
      sx={{
        position: "unset",
        width: "unset",
        height: "unset",
        svg: {
          fill: "none",
        },
      }}
    />
  );
};
