import { FormikHelpers } from "formik";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import notifications from "@/app/container/notifications";
import { AssetType, RegistryCode, useExportCommoditiesMutation } from "@/app/types/generated/graphql";

import { FormValues, ManagedCommoditiesExportModalProps, STEPS } from "./managed-commodities-export-modal.types";

export const useManagedCommoditiesExportModal = ({
  visible,
  closeModal,
  exportData,
  onNavigate,
}: ManagedCommoditiesExportModalProps) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState<STEPS>(STEPS.EXPORT_FORM);

  const modalTitles: Record<STEPS, string> = {
    [STEPS.EXPORT_SUBMIT]: `Are you sure you want to export commodity of serial ${exportData.serial} from account ${exportData.account?.company?.businessName}?`,
    [STEPS.EXPORT_SUCCESS]: "Your export request has been received.",
    [STEPS.EXPORT_FORM]: "Commodity Export",
  };
  const modalTitle = modalTitles[currentStep];

  const [exportCommodities] = useExportCommoditiesMutation();

  const onExportSubmit = useCallback(
    async (values: FormValues, action?: FormikHelpers<FormValues>) => {
      if (values && currentStep === STEPS.EXPORT_FORM) {
        action && action.setSubmitting(false);
        setCurrentStep(() => STEPS.EXPORT_SUBMIT);
      } else {
        try {
          const { data } = await exportCommodities({
            variables: {
              exportCommoditiesBySerialInput: {
                assetType: AssetType.Rec,
                commodityGroupUid: exportData.id,
                registryCode: RegistryCode.Mrets,
                serialIndexFrom: exportData.serialTo - Number(values.exportVolume) + 1,
                serialIndexTo: exportData.serialTo,
                serialPrefix: exportData.serialPrefix,
                tradingAccountUid: exportData.account.id,
              },
            },
          });
          if (data?.exportCommoditiesBySerial?.status) {
            setCurrentStep(() => STEPS.EXPORT_SUCCESS);
          }
        } catch (e) {
          e instanceof Error &&
            notifications.error({
              description: t(e?.message),
            });
        }
      }
    },
    [currentStep, exportCommodities, exportData, t],
  );

  const handleStepChange = useCallback((step: STEPS) => {
    setCurrentStep(step);
  }, []);

  return {
    visible,
    closeModal,
    exportData,
    onExportSubmit,
    currentStep,
    modalTitle,
    handleStepChange,
    onNavigate,
  };
};
