import { Flex } from "@powerledger/ui-component-lib";

import LogoCarbonDevice from "@/assets/logos/logo-carbon-device";

export const LoadingOverlayIndicator = () => (
  <Flex
    sx={{
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 999,
      display: "flex",
      position: "fixed",
      alignItems: "center",
      justifyContent: "center",
      background: "background",
    }}
  >
    <LogoCarbonDevice />
  </Flex>
);
