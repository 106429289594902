import { useTablePaginationManager } from "@powerledger/ui-component-lib";
import { startCase } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  RegistryServiceSortOrderInput,
  RetirementStatus,
  useRetirementHistoryAdminQuery,
} from "@/app/types/generated/graphql";

import { RetirementRequestsListTableData, useRetirementRequestsListFn } from "./retirement-requests-list.types";

export const retirementStatusOptions = Object.values(RetirementStatus).map((status) => ({
  label: startCase(status.toLowerCase()),
  value: status,
}));

export const useRetirementRequestsList: useRetirementRequestsListFn = () => {
  const [filterValues, setFilterValues] = useState({ status: RetirementStatus.Requested });
  const navigate = useNavigate();

  const { pageInfo, fetchData, resetPage, setTotalItems, offset, sort } = useTablePaginationManager<
    RetirementRequestsListTableData,
    RegistryServiceSortOrderInput[]
  >({});

  const {
    data: newData,
    loading,
    previousData,
    refetch,
  } = useRetirementHistoryAdminQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        offSetPaginationInfo: {
          offset,
          limit: pageInfo.pageSize,
        },
        sortOrderInputs: sort,
        where: {
          statuses: filterValues.status ? [filterValues.status] : null,
        },
      },
    },
    onCompleted(data) {
      setTotalItems(data?.retirementHistoryAdmin?.offsetInfo?.total ?? 0);
    },
  });

  const handleFilterChange = useCallback(
    (key, value) => {
      setFilterValues((prev) => ({
        ...prev,
        [key]: value,
      }));
      resetPage();
    },
    [resetPage],
  );

  const refreshData = useCallback(() => {
    refetch();
  }, [refetch]);

  const data = useMemo(
    () => (newData ?? previousData)?.retirementHistoryAdmin?.retirements ?? [],
    [newData, previousData],
  );

  const onSeeDetails = useCallback(
    (id: string) => {
      navigate(`${id}`);
    },
    [navigate],
  );

  return { handleFilterChange, filterValues, loading, refreshData, data, pageInfo, onSeeDetails, fetchData };
};
