import { Box, Button, Flex, Grid, LegacySelect } from "@powerledger/ui-component-lib";
import { Field, Formik } from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { AccountsSelect, ConfirmPaymentModal, Form, FormFieldLabel, FormInput } from "@/app/components";
import { AppCleaveTypes, CleaveInput } from "@/app/components/cleave-input";
import { FieldSelectors } from "@/app/hooks";
import { getSelectTranslation } from "@/app/lib/get-translations-for-components";
import { AccountStatus, CreatePaymentInput, PaymentType } from "@/app/types/generated/graphql";

import { ManualPaymentsViewProps } from "./manual-payments.types";
import { currencyCodeOptions, paymentTypeOptions, providerTypeOptions } from "./use-manual-payments";

export const ManualPaymentsView: FC<ManualPaymentsViewProps> = ({
  disableFields,
  formikProps,
  toggleShowConfirmModal,
  confirmCreatePayment,
  showConfirmModal,
  formRef,
  updateSelectedAccountLabels,
  selectedAccountLabels,
  loading,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Flex
        sx={{
          mt: 4,
          mb: 2,
          bg: "background",
          borderRadius: "8px",
          p: 3,
        }}
      >
        <Formik
          initialValues={formikProps?.initialValues || ({} as CreatePaymentInput)}
          innerRef={formRef}
          onSubmit={() => {
            toggleShowConfirmModal();
          }}
          enableReinitialize
          validationSchema={yup.object().shape({
            correlationId: yup.string(),
            type: yup.string().required(),
            amount: yup.object().shape({
              amount: yup.number().required().min(1),
              currencyCode: yup.string().required(),
            }),
            targetAccountId: yup.string().required(),
            sourceAccountId: yup.string().when("type", {
              is: (type: string) => type === PaymentType["Rebate"],
              then: yup.string().nullable().notRequired(),
              otherwise: yup.string().required(),
            }),
            providerType: yup.string().required(),
          })}
          {...formikProps}
          validateOnMount
        >
          {({ handleSubmit, values, errors, touched, isValid, setFieldTouched, setFieldValue }) => (
            <Form
              onSubmit={handleSubmit}
              sx={{
                mt: 4,
                fontSize: 1,
              }}
            >
              <Grid gap={3} columns={[2, null, 3]} sx={{ alignItems: "baseline" }}>
                <Form.Item key="manual-payments-correlation-id" sx={{ justifyContent: "end" }}>
                  <FormFieldLabel
                    hasErrorMessage={false}
                    small
                    name="correlationId"
                    sx={touched["correlationId"] && errors["correlationId"] ? FieldSelectors["select"] : {}}
                    label={t("Correlation ID")}
                    info={t(
                      "Correlation ID for the payment. For settlements, this would be a settlement ID that all related payments, including fees, is tied to",
                    )}
                  >
                    <FormInput debounce hasErrorIndicator={false} name="correlationId" />
                  </FormFieldLabel>
                </Form.Item>
                <Form.Item key="manual-payments-payment-type" sx={{ justifyContent: "end" }}>
                  <FormFieldLabel
                    hasErrorMessage={false}
                    small
                    name="type"
                    sx={touched["type"] && errors["type"] ? FieldSelectors["select"] : {}}
                    label={t("Payment Type *")}
                  >
                    <LegacySelect
                      translation={getSelectTranslation(t)}
                      value={paymentTypeOptions.find((type) => type.value === values?.type)}
                      options={paymentTypeOptions}
                      disabled={disableFields?.type}
                      onChange={(option) => {
                        setFieldValue("type", option?.value);
                      }}
                      onBlur={() => setFieldTouched("type", true, true)}
                    />
                  </FormFieldLabel>
                </Form.Item>

                <Form.Item key="manual-payments-to-id" sx={{ justifyContent: "end" }}>
                  <FormFieldLabel
                    hasErrorMessage={false}
                    small
                    name="targetAccountId"
                    label={t("To Account *")}
                    sx={touched["targetAccountId"] && errors["targetAccountId"] ? FieldSelectors["select"] : {}}
                  >
                    <AccountsSelect
                      selectedValue={{
                        label: selectedAccountLabels.TargetAccount || "",
                        value: values?.targetAccountId,
                      }}
                      filter={{
                        status: AccountStatus.Active,
                      }}
                      disabled={disableFields?.targetAccountId}
                      onFieldChange={(option) => {
                        setFieldValue("targetAccountId", option?.value ?? null);
                        updateSelectedAccountLabels({ TargetAccount: option?.label ?? "" });
                      }}
                      onBlur={() => setFieldTouched("targetAccountId", true, true)}
                    />
                  </FormFieldLabel>
                </Form.Item>
                {values?.type !== PaymentType.Rebate && (
                  <Form.Item key="manual-payments-from-id" sx={{ justifyContent: "end" }}>
                    <FormFieldLabel
                      hasErrorMessage={false}
                      small
                      sx={touched["sourceAccountId"] && errors["sourceAccountId"] ? FieldSelectors["select"] : {}}
                      name="sourceAccountId"
                      label={t("From Account *")}
                    >
                      <AccountsSelect
                        selectedValue={{
                          label: selectedAccountLabels.SourceAccount || "",
                          value: values?.sourceAccountId,
                        }}
                        filter={{
                          status: AccountStatus.Active,
                        }}
                        disabled={disableFields?.sourceAccountId}
                        onFieldChange={(option) => {
                          setFieldValue("sourceAccountId", option?.value ?? null);
                          updateSelectedAccountLabels({ SourceAccount: option?.label ?? "" });
                        }}
                        onBlur={() => setFieldTouched("sourceAccountId", true, true)}
                      />
                    </FormFieldLabel>
                  </Form.Item>
                )}
                <Form.Item key="manual-payments-amount" sx={{ justifyContent: "end" }}>
                  <FormFieldLabel hasErrorMessage={false} small name="amount.amount" label={t("Amount *")}>
                    <Flex
                      sx={{
                        alignItems: "center",
                        position: "relative",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          width: "150px",
                        }}
                      >
                        <LegacySelect
                          translation={getSelectTranslation(t)}
                          inputStyles={{
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                          }}
                          sx={{
                            height: "unset",
                          }}
                          options={currencyCodeOptions}
                          disabled={disableFields?.amount?.currencyCode}
                          value={currencyCodeOptions.find((option) => option.value === values?.amount?.currencyCode)}
                          onChange={(option) => setFieldValue("amount.currencyCode", option?.value)}
                        />
                      </Box>
                      <Field
                        name="amount.amount"
                        value={values?.amount?.amount}
                        validate={(value: string) => {
                          return !value;
                        }}
                        disabled={disableFields?.amount?.amount}
                        sx={{
                          width: "100%",
                          ml: -2,
                          ...(currencyCodeOptions.length < 2 && { zIndex: 1 }),
                          input: {
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            px: 3,
                          },
                        }}
                        onBlur={() => setFieldTouched("amount.amount", true, true)}
                        type={AppCleaveTypes.Amount}
                        component={CleaveInput}
                      />
                    </Flex>
                  </FormFieldLabel>
                </Form.Item>
                <Form.Item key="manual-payments-payment-method" sx={{ justifyContent: "end" }}>
                  <FormFieldLabel
                    hasErrorMessage={false}
                    small
                    sx={touched["providerType"] && errors["providerType"] ? FieldSelectors["select"] : {}}
                    name="providerType"
                    label={t("Provider Type *")}
                  >
                    <LegacySelect
                      translation={getSelectTranslation(t)}
                      options={providerTypeOptions}
                      disabled={disableFields?.providerType}
                      value={providerTypeOptions.find((option) => option.value === values?.providerType)}
                      onChange={(option) => {
                        setFieldValue("providerType", option?.value);
                      }}
                      onBlur={() => setFieldTouched("providerType", true, true)}
                    />
                  </FormFieldLabel>
                </Form.Item>
              </Grid>
              <Flex
                sx={{
                  justifyContent: "flex-end",
                }}
              >
                <Button type="submit" variant="primary" disabled={loading || !isValid}>
                  {t("Submit")}
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      </Flex>
      <ConfirmPaymentModal
        visible={showConfirmModal}
        onConfirm={confirmCreatePayment}
        closeModal={toggleShowConfirmModal}
        loading={loading}
        paymentData={{
          type: formRef?.current?.values.type || PaymentType["Rebate"],
          targetAccount: selectedAccountLabels.TargetAccount,
          sourceAccount: selectedAccountLabels.SourceAccount,
          amount: formRef?.current?.values.amount,
        }}
      />
    </>
  );
};
