import { Button, Flex, IconLoading, Modal } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";

import { useModalHelpers } from "@/app/hooks/use-modal-helpers";

import { ConfirmationModalProps } from "./confirmation-modal.types";

export default function ConfirmationModal({
  closeModal,
  visible,
  onSubmit,
  title,
  subtitle,
  content,
  loading = false,
  messages,
}: ConfirmationModalProps) {
  const { t } = useTranslation();

  const { onConfirm, submitting } = useModalHelpers({
    onSubmit,
    loading,
    closeModal,
    messages,
  });

  return (
    <Modal title={title} subtitle={subtitle} maskClosable visible={visible} hideCloseButton onCancel={closeModal}>
      {content}
      <Flex
        sx={{
          justifyContent: "flex-end",
          gap: 2,
        }}
      >
        <Button
          variant="error"
          sx={{
            width: 9,
          }}
          onClick={closeModal}
          disabled={submitting}
        >
          {t("Cancel")}
        </Button>
        <Button
          sx={{
            width: 9,
          }}
          onClick={onConfirm}
          disabled={submitting}
        >
          {t("Confirm")}
        </Button>
        {submitting && <IconLoading size="small" />}
      </Flex>
    </Modal>
  );
}
