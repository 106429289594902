export enum UserRoles {
  RoleSuperAdmin = "ROLE_SUPER_ADMIN",
  RoleInactiveUser = "ROLE_INACTIVE_USER",
  RoleVerificationsAdmin = "ROLE_VERIFICATIONS_ADMIN",
}

/**
 *
 * If user have some roles that routeRoles require than allow the access
 * If no userRoles is given, return false
 * @param routeRoles
 * @param userRoles
 * @returns
 */

export function grantAccess(routeRoles: UserRoles[], userRoles: UserRoles | UserRoles[]) {
  // if routeRoles is empty, then we don't want to allow access
  if (routeRoles.length === 0) {
    return false;
  }

  // Okta decode will either return a UserClaim string or an array of UserClaim strings
  // Therefore, convert userRoles to an array if it is a string
  if (typeof userRoles === "string") {
    userRoles = [userRoles];
  }

  // Some userRole must exist in the routeRoles array
  // if one of the userRoles does not exist in the routeRoles array, then deny access
  if (routeRoles.some((userRole) => userRoles?.includes(userRole))) {
    return true;
  }

  // if userRoles is empty or undefined, then we want to deny access
  return false;
}
