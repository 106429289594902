import currency from "currency.js";
import { FormikErrors, FormikProps } from "formik";
import { startCase } from "lodash";
import { useCallback, useMemo, useRef, useState } from "react";

import notifications from "@/app/container/notifications";
import {
  CreatePaymentInput,
  CurrencyCode,
  PaymentProviderType,
  PaymentType,
  useCreatePaymentMutation,
} from "@/app/types/generated/graphql";

import { ManualPaymentsViewProps, SelectedAccountLabels } from "./manual-payments.types";

export const currencyCodeOptions = (Object.keys(CurrencyCode) as Array<keyof typeof CurrencyCode>).map((key) => ({
  label: CurrencyCode[key],
  value: CurrencyCode[key],
}));

export const paymentTypeOptions = (Object.keys(PaymentType) as Array<keyof typeof PaymentType>).map((key) => ({
  label: startCase(key),
  value: PaymentType[key],
}));

export const providerTypeOptions = (Object.keys(PaymentProviderType) as Array<keyof typeof PaymentProviderType>).map(
  (key) => ({
    label: startCase(key),
    value: PaymentProviderType[key],
  }),
);

export const formatManualPaymentsFormValues = (values: CreatePaymentInput): CreatePaymentInput => ({
  ...values,
  amount: {
    ...values.amount,
    amount: currency(values.amount.amount).intValue.toString(),
  },
});

export const doCreatePayment = async ({
  createPayment,
  values,
  formHelpers,
}: {
  createPayment: ReturnType<typeof useCreatePaymentMutation>[0];
  formHelpers: {
    resetForm: () => void;
    validateForm?: (values?: any) => Promise<FormikErrors<CreatePaymentInput>>;
    setSubmitting?: (val: boolean) => void;
  };
  values?: CreatePaymentInput;
}) => {
  if (!values) throw new Error("Sorry, we can not process the details at the moment");
  try {
    await createPayment({
      variables: {
        input: formatManualPaymentsFormValues(values),
      },
    });

    notifications.success({
      description: "Successful Transaction",
    });
    formHelpers?.resetForm();
    formHelpers.validateForm && formHelpers.validateForm();
  } catch (e) {
    e instanceof Error &&
      notifications.error({
        message: "Error! ",
        description: e?.message,
      });
  } finally {
    formHelpers.setSubmitting && formHelpers.setSubmitting(false);
  }
};

export const useManualPayments = (): ManualPaymentsViewProps => {
  const [createPayment, { loading }] = useCreatePaymentMutation({
    notifyOnNetworkStatusChange: true,
  });
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedAccountLabels, setSelectedAccountLabels] = useState<SelectedAccountLabels>({
    SourceAccount: "",
    TargetAccount: "",
  });
  const formRef = useRef<FormikProps<CreatePaymentInput>>(null);

  const initialFormValues: CreatePaymentInput = useMemo(
    () => ({
      type: PaymentType["Rebate"],
      amount: {
        amount: "0",
        currencyCode: CurrencyCode.Usd,
      },
      targetAccountId: null,
      sourceAccountId: null,
      providerType: providerTypeOptions?.[0]?.value,
      correlationId: "",
    }),
    [],
  );

  const disableFields = useRef({
    amount: {
      amount: false,
      currencyCode: initialFormValues.amount.currencyCode && currencyCodeOptions.length < 2,
    },
    providerType: !!(initialFormValues.providerType && providerTypeOptions.length < 2),
    targetAccountId: false,
    sourceAccountId: false,
    type: initialFormValues.type && paymentTypeOptions.length < 2,
  }).current;

  const toggleShowConfirmModal = useCallback((value?: boolean) => {
    setShowConfirmModal((modalState) => value || !modalState);
  }, []);

  const updateSelectedAccountLabels = useCallback((arg: Record<string, string>) => {
    setSelectedAccountLabels((labels) => ({ ...labels, ...arg }));
  }, []);

  const confirmCreatePayment = useCallback(async () => {
    const resetForm = () => {
      formRef.current?.resetForm({
        values: initialFormValues,
      });
      updateSelectedAccountLabels({
        SourceAccount: "",
        TargetAccount: "",
      });
    };

    await doCreatePayment({
      createPayment,
      formHelpers: {
        resetForm,
        validateForm: formRef.current?.validateForm,
        setSubmitting: formRef.current?.setSubmitting,
      },
      values: formRef.current?.values,
    });
  }, [initialFormValues, updateSelectedAccountLabels, createPayment]);

  return {
    toggleShowConfirmModal,
    disableFields,
    showConfirmModal,
    confirmCreatePayment,
    formRef,
    updateSelectedAccountLabels,
    selectedAccountLabels,
    loading,
    formikProps: {
      initialValues: initialFormValues,
    },
  };
};
