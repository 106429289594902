import { Box, css, CSSProperties, Embed, getColor, Image, Link, Theme, useTheme } from "@powerledger/ui-component-lib";
import { memo, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";

import notifications from "@/app/container/notifications";
import { useDisableDevTools } from "@/app/hooks/use-disable-dev-tools";

const styles = (theme: Theme) =>
  ({
    imageWrapper: css({
      paddingTop: 3,
    })(theme),
    container: css({
      display: "flex",
      flexDirection: "column",
      padding: "16px",
      overflow: "hidden",
      alignItems: "center",
      fontFamily: "MintGroteskV08",
    })(theme),
    embed: css({
      width: "100vw",
      height: "88vh",
      margin: "auto",
    })(theme),
    link: css({
      color: getColor(theme, "text"),
      fontSize: "1em",
      textDecoration: "none",
      background: getColor(theme, "primary"),
      padding: "16px",
      borderRadius: 25,
      margin: "auto",
    })(theme),
  } as Record<string, CSSProperties>);

const Popup = ({ imageName = "", image = "", closePopup = () => {}, getWindowInstance = (window: Window) => {} }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  /**
   * Link Hovered Style to notify hover event
   * Reason as could not mount sx or css in elements that are rendered on new window
   * Had to defer to style
   * className not added as it would pollute already setup styling architecture
   */
  const [hoveredStyle, setHoveredStyle] = useState({});

  const [popupWindow, setPoupupWindow] = useState<Window | null>(null);

  useEffect(() => {
    popupWindow && getWindowInstance(popupWindow);
  }, [popupWindow, getWindowInstance]);

  useEffect(() => {
    /**
     * When we initialize new window on mount, if previous window is not closed, we will see a flicker, so useEffect and state is needed
     */
    const win = window.open(
      ``,
      "",
      "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=500,height=500,top=" +
        (screen.height - 400) +
        ",left=" +
        (screen.width - 840),
    );
    if (win) {
      win.document.head.innerHTML = `
      <title>${imageName}</title>
      <style>      
      body {
        background-color: ${getColor(theme, "background")};
        background-size: cover;
        font-family: MintGroteskV08;
        overflow: hidden;
      } 
      </style>
      `;
    }
    setPoupupWindow(win);
  }, [imageName, theme]);

  /**
   * If user's browser has popup disabled, show a notification and closePopup callback is called
   */
  useEffect(() => {
    /**
     * When one of dependency change, popupWindow is on the way, then it will show the notification and close the popup
     */
    const tim = setTimeout(() => {
      if (!popupWindow || popupWindow.closed || typeof popupWindow.closed == "undefined") {
        notifications.error({
          description: t("You need to allow Popups and Redirects for this site from your browser settings"),
        });
        closePopup();
      }
    }, 500);
    return () => clearTimeout(tim);
  }, [popupWindow, t, closePopup]);

  /**
   * Forbid Inspect Element
   * Just for future use if we have any important details to show without letting user right click and save them or inspect them
   */
  useDisableDevTools(popupWindow);
  /**
   * Cleanup for popup
   * Callback Call before Unload
   */
  useEffect(() => {
    const beforeUnload = () => {
      closePopup();
    };
    popupWindow?.addEventListener("beforeunload", beforeUnload);

    return () => {
      popupWindow?.removeEventListener("beforeunload", beforeUnload);
    };
  }, [closePopup, popupWindow]);

  if (popupWindow) {
    const allStyles = styles(theme);
    return createPortal(
      <Box style={allStyles.container}>
        <Link
          href={image}
          style={{ ...allStyles.link, ...hoveredStyle }}
          onMouseOver={() => {
            setHoveredStyle({ opacity: 0.8 });
          }}
          onMouseOut={() => setHoveredStyle({})}
          download={t("{{imageName}}", { imageName: `${imageName}` })}
        >
          {t("Download")}
        </Link>
        <Box style={allStyles.imageWrapper}>
          {/* 
          TODO: Research about adding styles to these mounted popups
          Adding styles to iframes is being difficult as sx prop is not working
          and is becoming rediculously hard to style iframes and its children, so used Image as we can just apply style to Image
          */}
          {image.includes("data:image") ? (
            <Image src={image} alt={imageName} />
          ) : (
            <Embed style={allStyles.embed} src={image} />
          )}
        </Box>
      </Box>,
      popupWindow.document.body,
    );
  } else return null;
};
export const DocumentPopUp = memo(Popup);
