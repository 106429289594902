import { ReactElement } from "react";
import { Box } from "theme-ui";

import PlaidLogoHorizontal from "@/assets/logos/plaid-logo-horizontal.svg";

import DwollaLogo from "../../assets/images/dwollaLogo.svg";
import { PaymentMethodCode } from "../types/generated/graphql";

export const PaymentProviderIconsMap: Record<string, ReactElement> = {
  DWOLLA: (
    <Box
      sx={{
        "svg>path:not(:nth-child(3))": {
          fill: "text",
        },
      }}
    >
      <DwollaLogo />
    </Box>
  ),
};
export const PaymentMethodIconsMap: Record<PaymentMethodCode, ReactElement> = {
  DWOLLA_PLAID: (
    <Box
      sx={{
        "svg>path:not(:nth-child(3))": {
          fill: "text",
        },
      }}
    >
      <PlaidLogoHorizontal />
    </Box>
  ),
  ICX: <></>,
};
