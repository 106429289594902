import { useCallback, useEffect, useState } from "react";

export const PERSIST_SIDEBAR = "persist:sidebar";

const getSidebarStateFromStorage = (): boolean => {
  const state = sessionStorage.getItem(PERSIST_SIDEBAR) ?? JSON.stringify(window.innerWidth > 1000);
  return JSON.parse(state);
};
const setSidebarStateToStorage = (value: boolean) => sessionStorage.setItem(PERSIST_SIDEBAR, JSON.stringify(value));

export const useSidebarOpen = () => {
  const [sidebarOpen, setSidebarOpen] = useState(getSidebarStateFromStorage);

  const toggleSidebarOpen = useCallback(() => {
    setSidebarStateToStorage(!sidebarOpen);
    setSidebarOpen(!sidebarOpen);
  }, [sidebarOpen]);

  useEffect(() => {
    window.addEventListener("beforeunload", () => setSidebarStateToStorage(sidebarOpen));
    return () => {
      window.addEventListener("beforeunload", () => setSidebarStateToStorage(sidebarOpen));
    };
  }, [sidebarOpen]);

  const handleSizeCheck = useCallback(() => window.innerWidth <= 1000 && setSidebarOpen(false), []);

  useEffect(() => {
    window.addEventListener("resize", handleSizeCheck);
    return () => {
      window.addEventListener("beforeunload", handleSizeCheck);
    };
  }, [sidebarOpen, handleSizeCheck]);

  return {
    sidebarOpen,
    toggleSidebarOpen,
  };
};
