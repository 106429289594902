import { addHours, addMinutes, endOfDay, format } from "date-fns";
import { FormikHelpers } from "formik";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import notifications from "@/app/container/notifications";
import {
  CurrentTradingHaltDocument,
  UpcomingTradingHaltsDocument,
  useCreateTradingHaltMutation,
  useTenantsQuery,
  useUpdateTradingHaltMutation,
} from "@/app/types/generated/graphql";

import { TradingHaltFormValues, UseCreateOrEditTradingHaltHook } from "./create-or-edit-trading-halt.types";

/**
 *
 * When passed a selectedDate, it will return minTime based on current Date (if selected date is current Date) or based on selected date at 12AM
 * When passed addMinMinutes option, it will add minutes to minTime
 * When passed startDate option
 * * * If selectedDate is passed above (1st scenario) is checked
 * * * Else minTime is calcualted based on given startDate option
 * Max time is always end of today / selectedDate / passed startDate
 * @param selectedDate
 * @param options
 * @returns
 */
export const getMinAndMaxTime = (
  selectedDate: string | null,
  options: {
    addMinMinutes: number;
    startDateTime: string | null;
  } = {
    addMinMinutes: 0,
    startDateTime: "",
  },
) => {
  const today = new Date();

  if (
    selectedDate &&
    !(
      today.getDate() === new Date(selectedDate).getDate() &&
      today.getFullYear() === new Date(selectedDate).getFullYear()
    )
  ) {
    return [
      addMinutes(new Date(new Date(selectedDate).setHours(0, 0, 0, 0)), options.addMinMinutes),
      endOfDay(new Date(selectedDate)),
    ];
  }
  const minTime = options.startDateTime
    ? addMinutes(new Date(options.startDateTime), options.addMinMinutes)
    : addMinutes(today, options.addMinMinutes);

  return [minTime, endOfDay(today)];
};

const formatDateForTradingHalt = (date = "", plusHours = 0) =>
  format(Date.parse(date || addHours(new Date(), plusHours).toString()), "yyyy/MM/dd p z");

export const useCreateOrEditTradingHalt: UseCreateOrEditTradingHaltHook = ({ onCancel, data, postSubmit }) => {
  const { t } = useTranslation();

  const [createTradingHalt] = useCreateTradingHaltMutation({
    refetchQueries: [CurrentTradingHaltDocument, UpcomingTradingHaltsDocument],
  });
  const [updateTradingHalt] = useUpdateTradingHaltMutation({
    refetchQueries: [CurrentTradingHaltDocument, UpcomingTradingHaltsDocument],
  });
  const { data: tenantsData, loading: tenantsLoading } = useTenantsQuery({
    onError(error) {
      notifications.error({
        description: t(error?.message),
      });
    },
  });

  const tenantsOptions =
    tenantsData?.tenants?.map((tenant) => ({
      label: tenant.name || "",
      value: tenant.id,
    })) || [];

  const initialValues = {
    startDateTime: formatDateForTradingHalt(data?.startDateTime),
    endDateTime: formatDateForTradingHalt(data?.endDateTime, 4),
    isApplicable: data?.isApplicable || false,
    isPlanned: data?.isPlanned || false,
    tenantId: data?.tenantId || tenantsData?.tenants?.[0]?.id,
  };

  const onSubmit = useCallback(
    async (values: TradingHaltFormValues, action: FormikHelpers<TradingHaltFormValues>) => {
      const formattedValues = {
        ...values,
        startDateTime: new Date(values.startDateTime).toISOString(),
        endDateTime: new Date(values.endDateTime).toISOString(),
      };
      try {
        if (data) {
          await updateTradingHalt({
            variables: {
              updateTradingHaltId: data.id,
              tradingHalt: formattedValues,
            },
          });
        } else {
          await createTradingHalt({
            variables: {
              tradingHalt: formattedValues,
            },
          });
        }
        notifications.success({
          description: t("Trading Halt Successfully {{action}}", {
            action: data ? "updated" : "created",
          }),
        });
        postSubmit && postSubmit();
      } catch (e) {
        e instanceof Error &&
          notifications.error({
            description: e?.message,
          });
      } finally {
        action.setSubmitting(false);
      }
    },
    [createTradingHalt, t, data, postSubmit, updateTradingHalt],
  );

  const isEdit = !!data;

  return {
    initialValues,
    tenantsOptions,
    tenantsLoading,
    onSubmit,
    onCancel,
    isEdit,
  };
};
