import { useTablePaginationManager } from "@powerledger/ui-component-lib";
import { startCase } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  ExportRequestStatus,
  RegistryServiceSortOrderInput,
  useExportRequestsQuery,
} from "@/app/types/generated/graphql";

import {
  ExportRequestsListFilter,
  ExportRequestsListTableData,
  useExportRequestsListFn,
} from "./export-request-list.types";

export const exportStatusOptions = Object.values(ExportRequestStatus).map((status) => ({
  label: startCase(status.toLowerCase()),
  value: status,
}));

export const useExportRequestsList: useExportRequestsListFn = () => {
  const [filterValues, setFilterValues] = useState<ExportRequestsListFilter>({});
  const navigate = useNavigate();

  const { pageInfo, fetchData, resetPage, setTotalItems, offset, sort } = useTablePaginationManager<
    ExportRequestsListTableData,
    RegistryServiceSortOrderInput[]
  >({});

  const {
    data: newData,
    loading,
    previousData,
    refetch,
  } = useExportRequestsQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        offSetPaginationInfo: {
          offset,
          limit: pageInfo.pageSize,
        },
        sortOrderInputs: sort,
        where: {
          statuses: filterValues.status ? [filterValues.status] : null,
          exportNumber: filterValues.exportNumber ? filterValues.exportNumber : null,
        },
      },
    },
    onCompleted(data) {
      setTotalItems(data?.exportRequests?.offsetInfo?.total ?? 0);
    },
  });

  const handleFilterChange = useCallback(
    (key, value) => {
      setFilterValues((prev) => ({
        ...prev,
        [key]: value,
      }));
      resetPage();
    },
    [resetPage],
  );

  const refreshData = useCallback(() => {
    refetch();
  }, [refetch]);

  const data = useMemo(() => (newData ?? previousData)?.exportRequests?.exportRequests ?? [], [newData, previousData]);

  const onSeeDetails = useCallback(
    (id: string) => {
      navigate(`${id}`);
    },
    [navigate],
  );

  return { handleFilterChange, filterValues, loading, refreshData, data, pageInfo, onSeeDetails, fetchData };
};
