import { LegacySelect } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";

import { getSelectTranslation } from "../../lib/get-translations-for-components";
import { AccountsSelectProps } from "./accounts-select.types";
import { useAccountsSelect } from "./use-accounts-select";

export const AccountsSelect: React.FC<AccountsSelectProps> = ({
  onFieldChange,
  filter,
  selectedValue,
  onBlur,
  disabled,
}) => {
  const { cachedAccountsOptions, getMoreData, onCustomSearch, totalAccounts } = useAccountsSelect({
    filter,
  });
  const { t } = useTranslation();

  return (
    <LegacySelect
      translation={getSelectTranslation(t)}
      isClearable
      value={selectedValue}
      placeholder={t("Enter Company Name to Search...")}
      options={cachedAccountsOptions}
      isPaginated
      getMoreData={getMoreData}
      disabled={disabled}
      onCustomSearch={onCustomSearch}
      hasMoreData={cachedAccountsOptions?.length < totalAccounts}
      onChange={(option) => {
        if (option) onFieldChange(option);
        else onFieldChange(null);
      }}
      onBlur={onBlur}
    />
  );
};
