import { CertificationAndEliglibilityType, formatAttributes } from "@/app/lib/format-attributes";
import { RECOrderAttributeOptions } from "@/app/lib/format-rec-options";
import { volumeFormatter } from "@/app/lib/volume-formatter";
import {
  CommodityTransactionStatus,
  ExportRequestStatus,
  ExportSummaryQuery,
  RecAssetAttributes,
} from "@/app/types/generated/graphql";

export type FormattedExportSummary = {
  id: string;
  vintage: string;
  project: string;
  eligibilities: CertificationAndEliglibilityType[];
  fuelSources: string[];
  location: string;
  volume: string;
  serialNumbers: string;
  status: CommodityTransactionStatus;
  statusMessage: string;
  commencementOperationDate: string;
  exportStatus: ExportRequestStatus;
};

export const formatExportReport = (
  exportDetails: ExportSummaryQuery["exportRequestById"],
  recOptions?: RECOrderAttributeOptions,
): FormattedExportSummary[] => {
  if (!recOptions) return [];
  if (!exportDetails.exportRequestDetails) return [];
  return exportDetails.exportRequestDetails.flatMap((detail) => {
    const exportAttributes = detail.attributes as RecAssetAttributes;
    const exportFormattedAttributes = formatAttributes({
      attributes: exportAttributes,
      options: recOptions,
    });
    const exportCommodities =
      detail.commodityTransactions?.map((transaction) => ({
        serialNumbers: `${transaction.commoditySerial.serialPrefix}-${transaction.commoditySerial.serialFrom} to ${transaction.commoditySerial.serialTo}`,
        status: transaction.status,
        statusMessage: transaction.statusMessage ?? "",
        id: transaction.id,
      })) ?? [];
    return exportCommodities.map((commodityInfo) => ({
      vintage: exportFormattedAttributes.vintage,
      project: exportFormattedAttributes.project,
      eligibilities: exportFormattedAttributes.certificationsAndEligibilities,
      fuelSources: exportAttributes.fuelSources || [],
      location: exportFormattedAttributes.location,
      volume: volumeFormatter(detail.volume),
      commencementOperationDate: exportAttributes.commencementDate ?? "-",
      exportStatus: detail.status,
      ...commodityInfo,
    }));
  });
};
