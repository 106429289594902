import { Box, Flex } from "@powerledger/ui-component-lib";
import { FC } from "react";

import { LayoutViewProps } from "./layout.types";

export const LayoutView: FC<LayoutViewProps> = ({
  siderWidth,
  headerHeight,
  children,
  tooltipComponent,
  footer,
  header,
  sider,
}) => {
  return (
    <Box sx={{ height: "100%", pl: siderWidth + 16, pr: 26 }}>
      {tooltipComponent}
      <Flex
        sx={{
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            marginTop: `calc(${headerHeight} + 16px)`,
            borderRadius: "8px",
            flex: 1,
            bg: "pageBackground",
          }}
        >
          {children}
        </Box>
        {footer}
      </Flex>
      <Box
        as="header"
        sx={{
          left: siderWidth,
          variant: "layouts.header",
        }}
      >
        {header}
      </Box>
      <Box
        as="aside"
        sx={{
          width: siderWidth,
          variant: "layouts.sider",
        }}
      >
        {sider}
      </Box>
    </Box>
  );
};
