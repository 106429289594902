import { DatePicker, DatePickerProps } from "@powerledger/ui-component-lib";
import { format } from "date-fns";
import * as Locales from "date-fns/locale";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { AppDateFormats, getDateBasedOnOffset, onChangeDateFormats } from "@/app/lib/format-date";

export type Languages = keyof typeof Locales;

export interface DatePickerCustomInputProps extends Omit<DatePickerProps, "onChange" | "dateFormat"> {
  onChange(value: any): void;
  /**
   * For dates like Date of Birth, we need exact date entered
   * React Date Picker takes in current offset and calculates the date based on offset
   * Hence if noOffset is passed, calculate the date without offset
   */
  noOffset?: boolean;

  dateFormat?: AppDateFormats;
}

export const LocalDatePicker: FC<DatePickerCustomInputProps> = ({ onChange, dateFormat, noOffset, ...props }) => {
  const { i18n } = useTranslation();
  const localeString = i18n?.language?.replace("-", "") as Languages;
  const date = getDateBasedOnOffset(props.value, noOffset);
  const dateViewFormat = dateFormat || AppDateFormats.UniversalDateFormat;
  return (
    <DatePicker
      locale={Locales[localeString]}
      {...props}
      onChange={(value) => {
        if (!Array.isArray(value))
          value ? onChange(format(value, onChangeDateFormats[dateViewFormat])) : onChange(value);
      }}
      dateFormat={dateViewFormat}
      selected={date}
    />
  );
};
