import { Box, getColor, TooltipProps, useTheme } from "@powerledger/ui-component-lib";
import ReactTooltip from "react-tooltip";

export const GenericTooltip = ({ content, ...props }: Partial<TooltipProps> & { content?: React.ReactElement }) => {
  const { theme } = useTheme();

  return (
    <ReactTooltip
      backgroundColor={getColor(theme, "shadow")}
      getContent={(tooltipContent) => (
        <Box sx={{ maxWidth: 300, color: "text", whiteSpace: "pre-line" }}>{content ?? tooltipContent}</Box>
      )}
      {...props}
    />
  );
};
