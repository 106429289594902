import { useState } from "react";
import { useTranslation } from "react-i18next";

import notifications from "@/app/container/notifications";

export function useModalHelpers<T extends Array<any> = []>({
  onSubmit,
  loading = false,
  closeModal,
  messages,
}: {
  onSubmit: (...args: T) => Promise<void>;
  loading?: boolean;
  closeModal: () => void;
  messages?: {
    successMessage?: string;
    errorMessage?: string;
  };
}) {
  const [submitting, setSubmitting] = useState(loading);
  const { t } = useTranslation();
  const onConfirm = async (...args: T) => {
    try {
      setSubmitting(true);
      await onSubmit(...args);
      notifications.success({
        description: messages?.successMessage || t("Success"),
      });
      closeModal();
    } catch (e) {
      e instanceof Error &&
        notifications.error({
          description: messages?.errorMessage || t(e?.message),
        });
    } finally {
      setSubmitting(false);
    }
  };

  return {
    onConfirm,
    submitting,
  };
}
