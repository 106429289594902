import { ApolloError } from "@apollo/client";
import { useOktaAuth } from "@okta/okta-react";
import { useState } from "react";

import { formatRecOptions, RECOrderAttributeOptions } from "@/app/lib/format-rec-options";
import { useRecOrderAttributesOptionsQuery } from "@/app/types/generated/graphql";

export const useRecOptions = (args?: { skipQuery?: boolean }) => {
  const okta = useOktaAuth();
  const tenantId = okta.oktaAuth?.authStateManager.getAuthState()?.accessToken?.claims?.tenantUid;

  const [formattedRecOptions, setFormattedRecOptions] = useState<RECOrderAttributeOptions>();

  const [accumulatedErrors, setAccumulatedErrors] = useState<ApolloError | Error | undefined>();

  const { loading: assetsLoading } = useRecOrderAttributesOptionsQuery({
    skip: !tenantId ?? args?.skipQuery ?? false,
    variables: {
      tenantId,
    },
    onCompleted: (data) => {
      try {
        const formattedOptions = formatRecOptions(data?.assets);
        setFormattedRecOptions(formattedOptions);
      } catch (error) {
        if (error instanceof Error) {
          setAccumulatedErrors(error);
        }
      }
    },
    onError: (error) => {
      setAccumulatedErrors(error);
    },
  });

  if (accumulatedErrors) {
    throw accumulatedErrors;
  }
  return {
    recOptions: formattedRecOptions,
    loading: assetsLoading,
    error: accumulatedErrors,
  };
};
