import { LegacySelect } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";
import { Box, Flex } from "theme-ui";

import { RefreshIconButton } from "@/app/components/refresh-icon-button";
import { RequestInfoMessage } from "@/app/components/retirement-export-requests-common";
import { getSelectTranslation } from "@/app/lib/get-translations-for-components";

import { RetirementRequestsListViewProps } from "./retirement-requests-list.types";
import RetirementRequestsListTable from "./retirement-requests-table/retirement-requests-table";
import { retirementStatusOptions } from "./use-retirement-requests-list";

export const RetirementRequestsListView: React.FC<RetirementRequestsListViewProps> = ({
  handleFilterChange,
  filterValues,
  loading,
  data,
  fetchData,
  pageInfo,
  onSeeDetails,
  refreshData,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Flex
        sx={{
          py: 3,
          mb: 3,
          gap: 2,
          flexWrap: "wrap",
          flexDirection: ["column", "row"],
          justifyContent: "flex-end",
        }}
      >
        <Box aria-label="filter retirement requests status" sx={{ width: "30%" }}>
          <LegacySelect
            sx={{
              [`div[class*="container"]>div`]: {
                bg: "background",
              },
              [`div[class*="menu"]>div`]: {
                bg: "background",
              },
            }}
            value={retirementStatusOptions.find((option) => option.value === filterValues.status)}
            options={retirementStatusOptions}
            translation={getSelectTranslation(t)}
            onChange={(option) => {
              option && handleFilterChange("status", option.value);
            }}
          />
        </Box>
        <RefreshIconButton loading={loading} fetchData={refreshData} />
      </Flex>
      <RequestInfoMessage />
      <RetirementRequestsListTable
        loading={loading}
        fetchData={fetchData}
        pageInfo={pageInfo}
        data={data}
        onSeeDetails={onSeeDetails}
      />
    </>
  );
};
