import { useEffect } from "react";

import { APOLLO_GRAPH_VARIANT } from "@/app/lib/env-helpers";

export const DevToolsKey = { J: "∆", I: "Dead", U: "Dead", S: "ß", F12: "F12", C: "c" };

const isOpenDevToolKey = (e: KeyboardEvent, code: string, ISmacOS: boolean) =>
  ISmacOS
    ? (e.metaKey && e.altKey && (code === DevToolsKey.I || code === DevToolsKey.J)) ||
      (e.metaKey && e.shiftKey && code === DevToolsKey.C)
    : e.ctrlKey && e.shiftKey && (code === DevToolsKey.I || code === DevToolsKey.J || code === DevToolsKey.C);

const isViewSourceCodeKey = (e: KeyboardEvent, code: string, ISmacOS: boolean) =>
  ISmacOS
    ? (e.metaKey && e.altKey && code === DevToolsKey.U) || (e.metaKey && code === DevToolsKey.S)
    : e.ctrlKey && (code === DevToolsKey.S || code === DevToolsKey.U);

export const keyDownDevHandler = (e: KeyboardEvent, env = "production") => {
  const ISmacOS = navigator.userAgent.toUpperCase().indexOf("MAC") >= 0;
  if (env !== "production") return;
  const keyCode = e.key;
  if (
    keyCode === DevToolsKey.F12 ||
    isOpenDevToolKey(e, keyCode, ISmacOS) ||
    isViewSourceCodeKey(e, keyCode, ISmacOS)
  ) {
    return e.preventDefault();
  }
};

export const contextMenuHandler = (e: MouseEvent, env = "production") => {
  if (env !== "production") return;
  return e.preventDefault();
};

export function useDisableDevTools(target: Window | null) {
  useEffect(() => {
    const tg = target || window;

    tg.addEventListener("keydown", (e) => keyDownDevHandler(e, APOLLO_GRAPH_VARIANT), true);
    tg.addEventListener("contextmenu", (e) => contextMenuHandler(e, APOLLO_GRAPH_VARIANT), true);
    return () => {
      tg.removeEventListener("keydown", (e) => keyDownDevHandler(e, APOLLO_GRAPH_VARIANT), true);
      tg.removeEventListener("contextmenu", (e) => contextMenuHandler(e, APOLLO_GRAPH_VARIANT), true);
    };
  }, [target]);

  return;
}
