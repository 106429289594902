import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useRecOptions } from "@/app/hooks/use-rec-options";
import { useExportSummaryQuery } from "@/app/types/generated/graphql";

import { UseExportRequestDetailsFn } from "./export-request-details.types";

export const useExportRequestDetails: UseExportRequestDetailsFn = () => {
  const navigate = useNavigate();
  const exportId = useParams().id;
  const {
    data: queryData,
    loading: queryLoading,
    refetch,
  } = useExportSummaryQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      id: exportId,
    },
  });

  const { recOptions, loading: recOptionsLoading } = useRecOptions();

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const refetchDetails = useCallback(() => {
    refetch();
  }, [refetch]);

  const loading = queryLoading ?? recOptionsLoading;

  const data = queryData?.exportRequestById;

  return {
    data,
    loading,
    goBack,
    refetchDetails,
    recOptions,
  };
};
