import { Button, Flex, IconLoading, IconWarning, Modal } from "@powerledger/ui-component-lib";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ConfirmDeleteTradingHaltModalProps } from "./confirm-delete-trading-halt-modal.types";

export default function ConfirmDeleteTradingHaltModal({
  closeModal,
  visible,
  onConfirm,
}: ConfirmDeleteTradingHaltModalProps) {
  const { t } = useTranslation();
  const [localLoading, setLocalLoading] = useState(false);

  const confirmSubmit = async () => {
    setLocalLoading(true);
    onConfirm && (await onConfirm());
    setLocalLoading(false);
    closeModal();
  };
  return (
    <Modal visible={visible} title={t("Delete Trading Halt?")} hideCloseButton>
      <Flex
        sx={{
          fontSize: 2,
          alignItems: "center",
        }}
      >
        <IconWarning color="yellow" size={6} />
        {t("Once confirmed, this action cannot be undone. Please be absolutely sure before proceeding.")}
      </Flex>
      <Flex
        sx={{
          justifyContent: "flex-end",
          gap: 2,
        }}
      >
        <Button onClick={closeModal} variant="error" disabled={localLoading}>
          {t("Cancel")}
        </Button>
        <Button onClick={confirmSubmit} disabled={localLoading}>
          {t("Confirm")}
        </Button>
        {localLoading && <IconLoading />}
      </Flex>
    </Modal>
  );
}
